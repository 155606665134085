<template>
  <div :id="instanceId" class="mr-tabs-nav__wrapper mr-tabs-nav__transparent mr-tabs-nav__simple mr-tabs-nav__icons">
    <div class="mr-tabs-nav__categories-list animated">
      <router-link
        v-for="(item, index) in items"
        :id="item.id"
        :key="getItemKey(item)"
        class="mr-tabs-nav__categories-item"
        :class="{ active: checkItemIsActive(item.id) }"
        :data-index="index"
        :to="{ name: item.pathName, params: params }"
      >
        <span class="mr-tabs-nav-border"></span>
        <span class="mr-tabs-nav-icon">
          <span v-if="statusIcons[item.id]" :class="`${statusIcons[item.id]}`" class="mr-tabs-nav-icon__status"></span>
          <icon-font v-if="item.icon" :size="item.iconSize" :icon="item.icon" />
        </span>
        {{ item.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { getReviseStatus } from '@/api/revise';
import IconFont from '@/components/icons/IconFont.vue';
import UserModule from '@/store/user';

export default {
  components: {
    IconFont,
  },
  props: {
    items: {
      type: Object,
      default() {
        return {
          1: {
            id: '1',
            name: 'One',
          },
          2: {
            id: '2',
            name: 'Two',
          },
          3: {
            id: '3',
            name: 'Three',
          },
        };
      },
    },
    actionName: {
      type: String,
      default: 'actionHandler',
    },
    params: {
      type: Object,
    },
    activeId: {
      type: String,
      default: '1',
    },
    instanceId: {
      type: String,
      default: '1',
    },
    parentLvl: {
      type: Number,
    },
  },
  data() {
    return {
      translatePos: 0,
      touchPos: 0,
      overflowRatio: 10,
      toRight: 'to-right',
      toLeft: 'to-left',
      screenWidth: 0,
      offsetWidth: 0,
      currentId: null,
      posBeforeScroll: 0,
      isiOs: false,
      statusIcons: {},
    };
  },
  beforeMount() {
    this.currentId = this.activeId;

    if (this.$route.fullPath.match('/tasks/')) {
      this.checkReviseStatus();
    }
  },
  updated() {
    this.currentId = this.activeId;
  },
  methods: {
    checkItemIsActive(id) {
      return [this.currentId, this.activeId].includes(id);
    },
    getItemKey(item) {
      const isActive = this.checkItemIsActive(item.id);

      return `${item.id}_${isActive}`;
    },
    async checkReviseStatus() {
      try {
        const doneClasses = 'mr-tabs-nav-icon__status--success icon-check-mark';
        const discussionClasses = 'mr-tabs-nav-icon__status--warn icon-discrepancy';

        if (UserModule.userHasPermission('CAN_READ_SHIFT_EMPLOYEE_CHECKUP')) {
          const { status } = await getReviseStatus();

          this.statusIcons['revise'] = status === 'discussion' ? discussionClasses : doneClasses;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
