import { createSharedComposable } from '@vueuse/core';
import { sendPushMessage } from '@/api/freeMarketShifts';
import UserModule from '@/store/user';
import { saveShiftById } from '@/api/shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { deepFreeze, flattenObject } from '@/lib/Utils';
import { FormData } from '@/interfaces/shared';
import { ShiftModalState } from '../interfaces';
import { useShiftEmployeeList } from './useShiftEmployeeList';
import { useShiftPayload } from './useShiftPayload';

export const useRecommendedEmployees = createSharedComposable(function (state: ShiftModalState) {
  let addedEmployees: Record<string, unknown> = {};
  const { getPaymentPartnerByStrategy } = useShiftEmployeeList(state);
  const { prepareEmployeesPayload } = useShiftPayload(state);

  const { prepareEditPayload } = useShiftPayload(state);
  const pristineEditPayload = deepFreeze(prepareEditPayload(UserModule.isSupervisor));

  function prepareEmployeeForPlanning(employeeId: string) {
    const paymentStrategy = state.paymentStrategy.id;
    const shiftPartner = state.partnerItems.find(({ id }) => id === pristineEditPayload?.partner);
    const employeePartnerUuid = UserModule.partner?.uuid;

    if (!pristineEditPayload || !shiftPartner || !paymentStrategy || !employeePartnerUuid) {
      return {};
    }

    const paymentPartner = getPaymentPartnerByStrategy({
      shiftPartner,
      employeePartner: { id: employeePartnerUuid, value: employeePartnerUuid },
      paymentStrategy,
    })?.id;

    return {
      employee: employeeId,
      intervalStart: pristineEditPayload?.intervalStart ?? '',
      intervalEnd: pristineEditPayload?.intervalEnd ?? '',
      lunch: state.lunch,
      paymentPartner,
      statusId: 'is_planned',
    };
  }

  function prepareRestPayload() {
    const result = {} as Record<string, string | number | undefined>;

    for (const property in pristineEditPayload) {
      if (!property.match(/^employees\[/)) {
        result[property] = pristineEditPayload[property];
      }
    }

    return result;
  }

  function prepareRecommendedEmployee(employeeId: string) {
    const employee = prepareEmployeeForPlanning(employeeId);

    let newEmployeeIndex = 0;
    for (const payloadKey in { ...pristineEditPayload, ...addedEmployees }) {
      const keyMatch = payloadKey.match(/^employees\[(\d+?)\]/);
      const lastIndex = keyMatch?.[1];

      if (lastIndex && +lastIndex >= newEmployeeIndex) {
        newEmployeeIndex = +lastIndex + 1;
      }
    }

    return { employee, index: newEmployeeIndex };
  }

  async function plan(employeeId: string) {
    if (!state.shiftFullEntity) {
      return;
    }

    const { employee: newEmployee, index: newEmployeeIndex } = prepareRecommendedEmployee(employeeId);
    const restEmployees = prepareEmployeesPayload();

    const countOfEmployees = Object.keys(restEmployees).reduce((count, key) => {
      if (key.match(/employees\[\d+?\]\[employee\]/)) {
        count++;
      }

      return count;
    }, 1);

    if (countOfEmployees > +state.needCountEmployees) {
      newEmployee.statusId = 'reserve';
    }

    const newEmployeePayload = flattenObject({ employees: { [newEmployeeIndex]: newEmployee } });

    try {
      const result = await saveShiftById(state.shiftFullEntity.id, {
        ...prepareRestPayload(),
        ...restEmployees,
        ...newEmployeePayload,
      } as FormData<string | number | undefined>);

      addedEmployees = { ...addedEmployees, ...newEmployeePayload };

      if (result.message) {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });
      }
    } catch (error) {
      throw ResponseHandlerModule.handleResponseError({
        error,
        defaultMessage: strings.UNKNOWN_ERROR,
        checkErrorFields: true,
        rethrowStrategy: 'anyway',
      });
    }
  }

  async function sendPush(employeeId: string) {
    try {
      await sendPushMessage(state.shiftId, employeeId);
    } catch (error) {
      throw ResponseHandlerModule.handleResponseError({
        error,
        defaultMessage: strings.UNKNOWN_ERROR,
        rethrowStrategy: 'anyway',
      });
    }
  }

  return {
    sendPush,
    plan,
  };
});
