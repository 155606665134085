import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

export enum PushStatus {
  IsNotSent = 'is_not_sent',
  IsSent = 'is_sent',
  IsFailed = 'is_failed',
  IsDelivered = 'is_delivered',
  IsRead = 'is_read',
}

export function prepareList(
  table: TableApiInterface,
  sort: { sort: string; key: string }[]
): ReturnType<UITable['getTable']> {
  const uiTable = new UITable(table as any);

  return uiTable
    .removeColumn('lastName')
    .removeColumn('firstName')
    .removeColumn('middleName')
    .removeColumn('_actions')
    .removeColumn('statusId')
    .removeColumn('substatusId')
    .removeColumn('cameOuts')
    .removeColumn('calendarWorkHours')
    .removeColumn('sharing')
    .addColumnByIndex(1, { id: 'fullName', name: 'ФИО', visible: true })
    .addColumn({ id: 'status', name: 'Статус и пуш', visible: true })

    .changeTitleOrder('workHours', 2)
    .changeTitleOrder('distance', 7)
    .changeTitleOrder('phone', 7)
    .setProperty('workHours', 'name', 'Выработка, ч')
    .setProperty('workHours', 'align', 'center')
    .setProperty('phone', 'name', 'Связь с исполнителем')
    .setProperty('timeLimit', 'name', '')
    .setProperty('relation', 'align', 'center')
    .setProperty('rating', 'align', 'center')
    .setProperty('status', 'align', 'right')
    .setProperty('distance', 'name', 'Удалённость, км')
    .setProperty('distance', 'align', 'right')
    .setSortableValues(sort)
    .getTable();
}
