
import { Component, Vue, Prop } from '@/lib/decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronLeft, faChevronRight);

@Component({})
export default class FreeMarketShiftsDate extends Vue {
  @Prop() date!: string;
  @Prop() day!: number;
  @Prop() text?: string;
  @Prop() min!: number;
  @Prop() max!: number;

  handlePrev(): void {
    if (this.day <= this.min) {
      return;
    }
    this.$emit('decrementDay');
  }

  handleNext(): void {
    if (this.day >= this.max) {
      return;
    }
    this.$emit('incrementDay');
  }
}
