
/**
 * Хедер (заголовок, фильтр и т.д.) для основных страниц сайта.
 */
import { defineComponent, PropType } from 'vue';
import TitlePage from '@/components/Title.vue';
import AddIcon from '@/components/icons/Add.vue';
import FilterModule from '@/store/modules/filter';
import FilterModal from '@/components/FilterDropDown.vue';
import FilterPreview from '@/components/FilterPreview.vue';
import IconFont from '@/components/icons/IconFont.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';

const component = defineComponent({
  name: 'AppHeaderMain',
  components: {
    TitlePage,
    FilterModal,
    AddIcon,
    FilterPreview,
    IconFont,
    ArrowReturn,
  },
  emits: ['toAdd', 'toSettings', 'clearSort', 'resetFilters', 'updateList', 'returnFromSettings', 'removePreviewTag'],
  props: {
    title: {
      type: String,
    },
    filterSettings: {
      type: Object as PropType<FilterSettings>,
      required: true,
    },
    addTooltip: {
      type: String,
    },
    totalItems: {
      type: String,
    },
    isToAddHidden: {
      type: Boolean,
    },
    isSettingsIcon: {
      type: Boolean,
    },
    isSettingsState: {
      type: Boolean,
    },
    isCenter: {
      type: Boolean,
    },
  },
  data() {
    return {
      focusKey: '',
    };
  },
  computed: {
    isShow() {
      return FilterModule.modal.isShow;
    },
  },
  methods: {
    toAdd() {
      this.$emit('toAdd');
    },
    toSettings() {
      this.$emit('toSettings');
    },
    clearSort() {
      this.$emit('clearSort');
    },
    closeFilter() {
      FilterModule.closeModal();
    },
    toggleFilter(_: any, key: string) {
      FilterModule.toggleModal();
      this.focusKey = key;
    },
    resetFilters() {
      this.$emit('resetFilters');
    },
    updateList() {
      this.$emit('updateList');
    },
    returnFromSettings() {
      this.$emit('returnFromSettings');
    },
  },
});

export default component;
