<template>
  <div ref="timestampPickerDefault" class="cp-input-fake__wrapper cp-timestamp-picker">
    <div
      class="mr-input-default__title cd-form-input-label"
      :class="{ 'cd-form-input-label-active': visible || modelValues.date }"
    >
      {{ modelValues.placeholder && !visible && !modelValues.date ? modelValues.placeholder : modelValues.title }}
    </div>

    <div class="cp-input-fake cp-input-radius-50" @click="open">
      <span v-if="modelValues.date" class="mr-input-fake__value">{{ modelValues.date }} {{ modelValues.time }}</span>
      <input type="text" style="position: absolute; top: -100000px; left: -100000px" @focus="open" />
      <icon-font class="mr-input-fake__icon-timestamp" size="13" icon="timestamp" />
    </div>

    <g-calendar v-if="visibleDate" :date="modelValues.date" :format="format" @select-value="selectValue" />

    <div v-if="visibleTime" class="cp-time-clock__wrapper">
      <div v-if="visibleTime" class="time-picker__content">
        <time-picker-component
          v-model="value.time"
          v-if="visibleTime"
          :hours-items="hoursItems"
          :minutes-items="minutesItems"
          :hoursCurrent="value.hour"
          :minutesCurrent="value.minute"
        />

        <div class="time-picker__save">
          <button type="button" class="cd-btn-primary cp-btn__height-24" @click="selectTimeValue">
            <span class="icon-check-mark"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimePickerComponent from '@/components/form/TimePicker/_component.vue';
import GCalendar from '@/components/form/Calendar';
import moment from 'moment';
import { compareDate } from '@/lib/Utils';
import IconFont from '@/components/icons/IconFont';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TimestampPicker',
  emits: ['click', 'init', 'close'],
  components: {
    IconFont,
    GCalendar,
    TimePickerComponent,
  },
  props: {
    modelValues: {
      type: Object,
      default() {
        return {
          key: 'time',
          type: 'time-range',
          title: 'Интервал смены',
          placeholder: '',
          time: '17:00',
          date: '15/03/2021',
          hour: '17',
          minute: '00',
          action: 'user/auth/updateLogin',
          required: true,
          start: 0,
          end: 24,
          step: 1,
        };
      },
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    disabled: {
      type: Boolean,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    onlyHour: {
      type: Boolean,
    },
  },
  data: () => ({
    isVisibleDate: false,
    visibleTime: false,
    blockChangeVisible: false,
    value: {
      time: '',
      hour: '',
      minute: '',
      date: null,
    },
    hoursItems: [],
    minutesItems: [],
    currentHour: '',
  }),
  computed: {
    visible() {
      const { visibleDate, visibleTime } = this;

      return visibleDate || visibleTime;
    },
    visibleDate: {
      set(value) {
        if (this.blockChangeVisible) return;

        this.isVisibleDate = value;

        if (!value) return;

        this.blockChangeVisible = true;
        setTimeout(() => {
          this.blockChangeVisible = false;
        }, 0);
      },
      get() {
        return this.isVisibleDate;
      },
    },
  },
  watch: {
    visible(flag) {
      const { value } = this;

      if (flag || !value.date) return;

      this.$emit('click', value);
      this.clearValue();
    },
    'value.time'(newValue) {
      if (newValue) {
        this.currentHour = newValue.split(':')[0];
        if (this.min) {
          this.updateTimeArrays();
        }
      }
    },
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.timestampPickerDefault;
      const target = e.target;

      if (!this.visible || !el || el === target || el.contains(target)) return;

      this.visibleDate = false;
      this.visibleTime = false;
      this.$emit('close');
    },
    open() {
      if (this.disabled) return;

      this.visibleDate = true;
    },
    validate(params) {
      const date = `${params.day}/${params.month}/${params.year}`;
      const { min, max } = this;

      return (!min || compareDate(date, min, this.format) > -1) && (!max || compareDate(max, date, this.format) > -1);
    },
    selectValue(value) {
      const date = moment(value, this.format);

      const newDate = {
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY'),
      };

      if (!this.validate(newDate)) {
        return;
      }
      this.value.date = newDate;
      if (this.min) {
        this.updateTimeArrays();
      }

      this.visibleTime = true;
      setTimeout(() => {
        this.visibleDate = false;
      }, 0);
    },
    updateTimeArrays() {
      const valueDate = `${this.value.date.day}/${this.value.date.month}/${this.value.date.year}`;
      const minDate = moment(this.min, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');

      if (valueDate === minDate) {
        const hour = moment(this.min, 'DD/MM/YYYY HH:mm').format('HH');

        const hourInt = hour[0] === '0' ? parseInt(hour[1]) : parseInt(hour);

        const minute = moment(this.min, 'DD/MM/YYYY HH:mm').format('mm');

        const minuteInt = minute[0] === '0' ? parseInt(minute[1]) : parseInt(minute);

        this.hoursItems = [];
        for (let i = 55 < minuteInt ? 1 + hourInt : hourInt; i <= 23; i++) {
          this.hoursItems.push(`${i <= 9 ? '0' : ''}${i}`);
        }

        this.minutesItems = [];
        for (let i = this.currentHour === hour && 55 > minuteInt ? minuteInt : 0; i <= 59; i++) {
          if (i % 5 === 0) {
            this.minutesItems.push(`${i <= 9 ? '0' : ''}${i}`);
          }
        }
      } else {
        this.hoursItems = [];
        for (let i = 0; i <= 23; i++) {
          this.hoursItems.push(`${i <= 9 ? '0' : ''}${i}`);
        }

        this.minutesItems = [];
        for (let i = 0; i <= 59; i++) {
          if (i % 5 === 0) {
            this.minutesItems.push(`${i <= 9 ? '0' : ''}${i}`);
          }
        }
      }
    },
    selectTimeValue() {
      const splitValueTime = this.value.time.split(':');
      this.value.hour = splitValueTime[0];
      this.value.minute = splitValueTime[1];
      this.visibleTime = false;
    },
    clearValue() {
      this.value = {
        time: '',
        hour: '',
        minute: '',
        date: null,
      };
    },
  },
  created() {
    document.body.addEventListener('click', this.documentClick);
    this.currentHour = moment().format('HH');
  },
  unmounted() {
    document.body.addEventListener('click', this.documentClick);
  },
});
</script>
