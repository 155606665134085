import { computed } from 'vue';
import useVuelidate, { ValidationRuleCollection } from '@vuelidate/core';
import { createSharedComposable } from '@vueuse/core';
import validator from '@/lib/validator';
import validatorMessages from '@/lib/validator/messages';
import UserModule from '@/store/user';
import ShopEntityModule from '@/store/shops/entity';
import { ShiftModalState } from '../interfaces';
import { defaultNewShiftTemplate } from './useShiftFormSelectItems';

export const useShiftFormValidate = createSharedComposable(function (state: ShiftModalState) {
  const validationRules = computed(() => {
    const greatThanZero = validator({
      name: 'minValue',
      message: 'Значение должно быть больше нуля',
      attributes: 1,
    });

    const minRate = validator({
      name: 'or',
      message: () => (!state.isTraining ? 'Значение должно быть больше нуля' : ''),
      attributes: [greatThanZero, () => state.isTraining],
    });

    const maxRate = validator({
      name: 'maxValue',
      message: 'Не должна быть больше внешней ставки',
      attributes: state.externalRate || Infinity,
    });

    const minFromAge = validator({
      name: 'minValue',
      message: 'Минимальное значение - 14',
      attributes: 14,
    });

    const minToAge = validator({
      name: 'minValue',
      message: 'Введён неверный диапазон',
      attributes: Number(state.ageFrom) <= 14 ? 14 : state.ageFrom,
    });

    const maxFromAge = validator({
      name: 'maxValue',
      message: 'Введён неверный диапазон',
      attributes: state.ageFrom,
    });

    const maxToAge = validator({
      name: 'maxValue',
      message: 'Введён неверный диапазон',
      attributes: 100,
    });

    const required = validator({
      name: 'required',
    });

    const url = validator({
      name: 'url',
    });

    const localRules: Record<string, ValidationRuleCollection> = {
      needCountEmployees: {
        required,
        minValue: greatThanZero,
      },
      shiftTemplate: {
        required: validator({
          name: 'or',
          message: validatorMessages.required,
          attributes: [() => state.shiftTemplate.id || defaultNewShiftTemplate.value === state.shiftTemplate.value],
        }),
      },
      rate: {
        required,
        minRate,
        maxRate,
      },
      externalRate: {
        required,
        minRate,
      },
      ageFrom: {
        required,
        minFromAge,
        maxFromAge,
      },
      ageTo: {
        required,
        minToAge,
        maxToAge,
      },
      educationLink: {
        url,
      },
      unitType: {
        required,
      },
      price: {
        required,
        greatThanZero,
      },
      quantity: {
        required,
        greatThanZero,
      },
    };

    const shopContractor = state.contractor || ShopEntityModule.model.contractor;
    const isCurrentPartnerContractor = !!shopContractor && shopContractor === UserModule.partner?.uuid;
    const isSupervisor = UserModule.isSupervisor;

    if (!isCurrentPartnerContractor && !isSupervisor) {
      const rules: Partial<typeof localRules> = localRules;

      rules.rate = {};
      rules.externalRate = {};
      rules.ageFrom = {};
      rules.ageTo = {};
      rules.educationLink = {};
      rules.price = {};
      rules.quantity = {};
    }

    return localRules;
  });

  const v$ = useVuelidate(validationRules, state);
  const isValidForm = computed(() => !v$.value.$silentErrors.length && state.dates.length);

  function getFieldValidator(name: keyof typeof validationRules.value) {
    const validator = v$.value[name];
    const errorMessage = (validator?.$silentErrors?.[0]?.$message || '') as string;

    return {
      isInvalid: validator?.$invalid ?? false,
      errorMessage,
    };
  }

  return {
    v$,
    isValidForm,
    getFieldValidator,
  };
});
