/**
 * Global API for free market shifts.
 *
 */
import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest, makeDeleteRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { FormResponse, FormData } from '@/interfaces/shared';

/**
 * Get free shifts list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_FREE_MARKET_SHIFT
 */
export const getFreeMarketShiftsList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<{ table: TableApiInterface }> => {
  const assemblerUrl = new AssemblerUrl(API.freeMarketShifts.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Employees list for free shifts.
 */
export const getEmployeesShiftsList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<{ table: TableApiInterface }> => {
  const assemblerUrl = new AssemblerUrl(API.freeMarketShifts.getEmployeesList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();

  const result = await makeGetRequest(url);

  return result.data.result;
};

export const sendPushMessage = async (shift: string, employee: string): Promise<FormResponse> => {
  const url = API.freeMarketShifts.sendPushMessage;
  const result = await makeFormRequest(url, { shift, employee });

  return result.data;
};

/**
 * Add employee to shift
 *
 * @param shiftId
 * @param data
 */
export const addEmployeeToShift = async (shiftId: string, data: FormData): Promise<FormResponse> => {
  const url = API.freeMarketShifts.addEmployee.replace('$1', shiftId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete employee from shift
 *
 * @param shiftId
 * @param employeeId
 */
export const deleteEmployeeFromShift = async (shiftId: string, employeeId: string): Promise<FormResponse> => {
  const url = API.freeMarketShifts.deleteEmployee.replace('$1', shiftId).replace('$2', employeeId);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Refuse shift
 *
 * @param data
 */
export const shiftRefuse = async (data: FormData): Promise<FormResponse> => {
  const url = API.freeMarketShifts.refuseShift;
  const result = await makeFormRequest(url, data);

  return result.data;
};
