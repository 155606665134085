export type FreeMarketShiftModalDateType =
  | string
  | Record<string, string | boolean | number | Record<string, string | number>>;
export type FreeMarketShiftModalParamsType = Record<
  string,
  Record<string, string | boolean | Record<string, FreeMarketShiftModalDateType>>
>;

export const modalParams: FreeMarketShiftModalParamsType = {
  dateRange: {
    model: {
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: '',
        value: '',
        action: 'freeMarketShift/updateDateStart',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: '',
        value: '',
        action: 'freeMarketShift/updateDateEnd',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
    },
  },
};
