import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

export function prepareList(table: TableApiInterface, state: any) {
  const uiTable = new UITable(table as any);
  const sorts = Object.values(state.pageSettings.sort) as { sort: string; key: string }[];

  return uiTable
    .removeColumn('id')
    .removeColumn('item')
    .removeColumn('end')
    .removeColumn('market_code')
    .removeColumn('isTestClient')
    .removeColumn('isTestMarket')

    .setProperty('start', 'name', 'Заказ с/до')
    .setProperty('partner_name', 'name', 'Партнер')
    .setProperty('market_name', 'name', 'Объект')

    .setSortableValues(sorts)

    .getTable() as any;
}
