
import { defineComponent, PropType, nextTick, ref } from 'vue';
import Search from '@/components/Search.vue';
import { directive as clickOutside } from 'v-click-outside';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import FormSwitch from '@/components/form/Switch/index.vue';
import FormTimePickerRange from '@/components/form/TimePicker/Range.vue';
import FormTimestampPicker from '@/components/form/TimePicker/TimestampPickerFilter.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';

import FilterModule from '@/store/modules/filter';
import { FilterEntityModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import Inputmask from 'inputmask';
import formValidate from '@/lib/formFactory/validate';
import IconFont from '@/components/icons/IconFont.vue';
import { GuiFormCheckbox } from '@library/gigant_ui';

const component = defineComponent({
  name: 'FilterModal',
  components: {
    GuiFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    Search,
    DatePicker,
    FormInput,
    FormSelect,
    // eslint-disable-next-line vue/no-unused-components
    FormMultiSelect,
    FormSwitch,
    FormTimePickerRange,
    FormTimestampPicker,
    IconFont,
    FormButton,
    FormAutocomplete,
  },
  directives: { clickOutside },
  emits: ['resetFilters', 'updateList'],
  props: {
    settings: {
      type: Object as PropType<FilterSettings>,
      required: true,
    },
    handlers: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object as PropType<Record<string, unknown>>,
    },
    focusKey: {
      type: String,
    },
  },
  data() {
    return {
      form: ref(null),
      formValidate: formValidate(this.form),

      autoCompleteList: [],
    };
  },
  computed: {
    isShow() {
      return FilterModule.modal.isShow;
    },
  },
  watch: {
    isShow(val: boolean) {
      if (val) {
        this.setListeners();
        this.focusSearchInput();
        nextTick(this.addMasks);

        return;
      }
      this.resetListeners();
    },
  },
  methods: {
    async updateCheckbox(
      value: { target: { checked: boolean } },
      params: { key: string; action: string; id: string; name: string }
    ) {
      this.$store.dispatch(params.action, {
        key: params.key,
        checkbox: {
          id: params.id,
          name: params.name,
          bool: value.target.checked,
        },
      });
    },
    async updateSwitch(value: { target: { checked: boolean } }, params: { action: string; key: string }) {
      await this.$store.dispatch(params.action, { key: params.key, value }, { root: true });
    },
    async handleSelect(selected: Record<string, string | number>, params: { action: string; key: string }) {
      await this.$store.dispatch(params.action, {
        key: params.key,
        value: selected,
      });
    },
    async handleAutocompleteSearch(selected: unknown, params: { action: string; key: string }) {
      this.autoCompleteList = await this.$store.dispatch(params.action, {
        key: params.key,
        value: selected,
      });
    },
    async handleAutocomplete(selected: Record<string, string | number>, params: { action: string; key: string }) {
      await this.$store.dispatch(params.action, {
        key: params.key,
        value: selected,
      });
    },
    closeModal() {
      FilterModule.closeModal();
      this.$store.dispatch(this.handlers?.resetState);
    },
    async handleMultiSelect(selected: Record<string, string | number>, params: { action: string; key: string }) {
      await this.$store.dispatch(params.action, {
        key: params.key,
        value: selected,
      });
    },
    handleDate(params: { key: string; date: { day: number; month: string; year: string } }, action: string) {
      if (!action) {
        return;
      }

      this.$store.dispatch(action, params, { root: true });
    },
    handleTime(params: { key: string; time: Record<string, string | Record<string, string>> }, action: string) {
      this.$store.dispatch(action, params, { root: true });
    },
    handleTimestamp(params: { key: string; timestamp: Record<string, string | Record<string, string>> }, action: string) {
      this.$store.dispatch(action, params, { root: true });
    },
    clearDate(clearAction: string) {
      this.$store.dispatch(clearAction, {}, { root: true });
    },
    async executeClear() {
      FilterModule.closeModal();
      this.$emit('resetFilters');
    },
    async executeYes() {
      FilterModule.closeModal();
      this.$emit('updateList');
    },
    setListeners() {
      document.addEventListener('keyup', this.listenerHandler);
    },
    resetListeners() {
      document.removeEventListener('keyup', this.listenerHandler);
    },
    listenerHandler(e: KeyboardEvent) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.executeYes();
      }

      if (e.key === 'Esc' || e.keyCode === 27) {
        this.executeClear();
      }
    },
    async focusSearchInput() {
      const searchFilters = Object.values(this.settings.filterModel);

      if (searchFilters.length === 0) {
        return;
      }

      const filterItem = searchFilters.find((el) => el.key === this.focusKey);
      const targetFilter = filterItem || (searchFilters[0] as any);

      let el: HTMLElement | null = null;

      if (targetFilter.class) {
        await nextTick();
        el = document.querySelector(`.${targetFilter.class} input`) as HTMLElement;
      }

      if (!el && targetFilter.placeholder) {
        await nextTick();
        el = document.querySelector(
          `.mr-search-input__wrapper input[placeholder="${targetFilter.placeholder}"]`
        ) as HTMLElement;
      }

      if (el) {
        el.focus();
      }
    },
    addMasks() {
      Object.values(this.settings.filterModel).forEach((item: FilterEntityModel) => {
        if (item.mask) {
          const settingsMask = new Inputmask(item.mask);
          const inputs = document.getElementsByClassName(item.class as string);

          for (const input of Object.values(inputs)) {
            settingsMask.mask(input.getElementsByTagName('input'));
          }
        }
      });
    },
    clearSelect(data: { clearAction: string; key: string }) {
      this.$store.dispatch(data.clearAction, data.key);
    },
    updateSearch(data: Record<string, string>) {
      this.$store.dispatch(data.action, { key: data.key, value: data.value });
    },
  },
});

export default component;
