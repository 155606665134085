/**
 * Response Handler store.
 *
 */

import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';

export const MODULE_NAME = 'filter';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FilterModule extends VuexModule {
  modal = {
    isShow: false,
  };

  @Mutation
  SET_MODAL_STATE(isShow: boolean) {
    this.modal.isShow = isShow;
  }

  @Action({ rawError: true })
  openModal() {
    this.context.commit('SET_MODAL_STATE', true);
  }

  @Action({ rawError: true })
  closeModal() {
    this.context.commit('SET_MODAL_STATE', false);
  }

  @Action({ rawError: true })
  toggleModal() {
    this.context.commit('SET_MODAL_STATE', !this.modal.isShow);
  }
}

export default getModule(FilterModule);
