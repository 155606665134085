<template>
  <div ref="timePickerDefault" class="cp-time-picker__wrapper cp-cursor-pointer" :class="{ disabled: disabled }">
    <div class="cp-time-picker">
      <span class="mr-input-fake__value">{{ value }}</span>

      <div class="cp-picker-clock__wrapper">
        <div class="cp-picker-clock__bg"></div>
        <div class="cp-picker-clock__bg-sm"></div>

        <picker-clock class="cp-picker-clock__icon" />
      </div>
    </div>
  </div>
</template>

<script>
import PickerClock from '@/components/icons/PickerClock.vue';

export default {
  name: 'form-time-picker-range-icon',
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    PickerClock,
  },
};
</script>
