import { ref, watch } from 'vue';
import { createSharedComposable } from '@vueuse/core';
import { getCitizenshipsAll } from '@/api/citizenship';
import { getClientLunchSchemes, getLunchSchemes } from '@/api/client';
import { getAllTemplatesByShopId, getServiceTypesByStore, getTemplateInfo, getUnitTypeAll } from '@/api/templates';
import { TemplateRequestModel } from '@/interfaces/models/template.interface';
import { MultiSelectItem } from '@/interfaces/multiSelect.interface';
import { gendersAll } from '@/lib/Genders';
import { PaymentStrategies } from '@/lib/PaymentStrategies';
import { deepFreeze, minutesToTime } from '@/lib/Utils';
import { RadioForm } from '@/lib/formFactory/radioForm.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import ShopEntityModule from '@/store/shops/entity';
import UserModule from '@/store/user';
import { ShiftModalState } from '../interfaces';
import { modifyStateByTemplate } from './useShiftModalState';
import { useShiftFormComputedProps } from './useShiftFormComputedProps';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { getAllPartnersList } from '@/api/partners';

export const defaultNewShiftTemplate = { id: '', value: 'Новый шаблон' };
export const updateFutureItems = [
  { id: '', value: 'Не изменять ставку на будущих заказах' },
  { id: 'update_shift_rates', value: 'Изменить ставку на будущих заказах, не меняя ставки исполнителей' },
  { id: 'update_shift_employee_rates', value: 'Изменить ставку на будущих заказах и записанных исполнителях' },
];

export const useShiftFormSelectItems = createSharedComposable(function (state: ShiftModalState) {
  const paymentStrategies = new PaymentStrategies();
  const paymentStrategyItems = ref<SelectItem[]>([]);
  const { isContractorOrSupervisor } = useShiftFormComputedProps(state);

  function initPartnerWatchForPaymentStrategy() {
    watch(
      () => state.partner,
      (selectedPartner, oldPartner) => {
        if (
          ShopEntityModule.model.clientSubcontractors.some((item) => item.partner.uuid === selectedPartner.id) &&
          selectedPartner.id !== oldPartner.id
        ) {
          state.paymentStrategy.id = ShopEntityModule.model.paymentStrategy;
          state.paymentStrategy = paymentStrategies.getSelectItemForPartner(selectedPartner, state.paymentStrategy.id);
        } else if (ShopEntityModule.model.paymentStrategy === 'gigant_pays' && !state.paymentStrategy.id) {
          state.paymentStrategy = paymentStrategies.getSelectItemForPartner(
            selectedPartner,
            ShopEntityModule.model.paymentStrategy
          );
        } else {
          if (state.paymentStrategy.id) {
            state.paymentStrategy = paymentStrategies.getSelectItemForPartner(selectedPartner, state.paymentStrategy.id);
          } else {
            state.paymentStrategy = paymentStrategies.getSelectItemForPartner(selectedPartner);
          }
        }
        state.paymentStrategyItems = paymentStrategies.getSelectItemsForPartner(selectedPartner);
      }
    );
  }

  function setSelectValue<
    T extends SelectItem
  >(id: string, items: T[], stateProp: keyof Pick<ShiftModalState, 'partner' | 'serviceType'>, defaultItem?: T) {
    const foundItem = items.find((item) => item.id === id);

    if (foundItem) {
      state[stateProp] = foundItem;
    } else if (defaultItem) {
      state[stateProp] = defaultItem;
    }
  }

  async function getPartnerItems() {
    const result = await getAllPartnersList();

    const shopPartnerUuid = ShopEntityModule.model.clientPartners.filter(
      (item) => item.partner.uuid === UserModule.partner?.uuid
    )[0];
    const mainShopPartnerUuid = ShopEntityModule.model.clientPartners.filter((item) => item.main)[0].partner.uuid;
    const defaultPartnerUuid =
      state.shiftFullEntity?.partner.uuid ||
      (shopPartnerUuid && !UserModule.isSupervisor ? shopPartnerUuid.partner.uuid : '') ||
      mainShopPartnerUuid ||
      UserModule.partner?.uuid ||
      '';

    const items = ShopEntityModule.model.clientPartners
      .filter((partner) => partner.partner.isSupplier)
      .map((partner) => ({
        id: partner.partner.uuid,
        value: partner.partner.legalName,
        inn: partner.partner.inn ?? '',
        isSupervisor: result.filter((item) => item.uuid === partner.partner.uuid)[0]?.isSupervisor ?? false,
      }));

    const defaultPartner = items.find((partner) => defaultPartnerUuid === partner.id);
    if (defaultPartner) {
      state.partner = defaultPartner;
    }

    return items;
  }

  async function getServiceTypeItems(shopId: string) {
    const result = await getServiceTypesByStore(shopId);

    return result.map((serviceType) => ({
      id: serviceType.id.toString(),
      value: serviceType.name,
    }));
  }

  async function getUnitTypeItems() {
    const result = await getUnitTypeAll();

    return result.map((unitType) => ({
      id: unitType.id.toString(),
      value: unitType.name,
    }));
  }

  async function getShiftTemplateItems(shopId: string) {
    const result = await getAllTemplatesByShopId(shopId);
    const preparedList = result
      .sort((a, b) => a.count - b.count)
      .map((shiftTemplate) => ({
        id: shiftTemplate.id.toString(),
        value: shiftTemplate.name,
      }));

    if (isContractorOrSupervisor.value) {
      preparedList.unshift(defaultNewShiftTemplate);
    }

    return preparedList;
  }

  async function getCitizenshipItems(): Promise<MultiSelectItem[]> {
    const citizenships = await getCitizenshipsAll();
    citizenships.sort((a, b) => a.sortOrder - b.sortOrder);

    const items: MultiSelectItem[] = [];

    for (const citizenship of citizenships) {
      const item = {
        id: citizenship.id,
        name: citizenship.name,
        value: citizenship.name,
        checked: false,
      };

      items.push(item);

      if (citizenship.name === 'Россия' && !state.shiftId && !state.shiftFullEntity) {
        item.checked = true;
        state.citizenships.push(item);
      }
    }

    return items;
  }

  async function getLunchItems(clientId: string) {
    try {
      const lunchSchemes = await getClientLunchSchemes(clientId);

      lunchSchemes.push({
        duration: 0,
        isPaid: false,
      });

      return lunchSchemes
        .sort((a, b) => a.duration - b.duration)
        .map((scheme) => ({
          id: scheme.duration.toString(),
          value: minutesToTime(scheme.duration),
          data: scheme,
        }));
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  async function getShiftLunchItems(clientId: string) {
    try {
      const lunchSchemes = await getLunchSchemes(clientId);

      return lunchSchemes
        .sort((a, b) => a.duration - b.duration)
        .map((scheme) => ({
          id: scheme.duration.toString(),
          value: minutesToTime(scheme.duration),
          data: scheme,
        }));
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  async function fetchShiftTemplateInfo(id: string) {
    let result: TemplateRequestModel | undefined = state.shiftTemplateEntitiesLoaded[id];

    if (!result) {
      result = await getTemplateInfo(id);
    }

    const freezedResult = deepFreeze(result);
    state.shiftTemplateEntitiesLoaded[id] = freezedResult;

    return freezedResult;
  }

  async function fetchItems(shopId: string, serviceTypeId: string, isEdit: boolean) {
    state.isLoadingItems = true;

    try {
      const clientId = (state.shiftFullEntity?.clientMarket.client.id ?? ShopEntityModule.model.clientId)?.toString();
      const shiftId = (state.shiftFullEntity?.id ?? ShopEntityModule.model.shiftId ?? state.shiftId)?.toString();

      await paymentStrategies.init();
      state.paymentStrategyItems = paymentStrategies.getSelectItemsForPartner(state.partner);

      const [partners, serviceTypes, shiftTemplates, citizenships, lunchSchemes, unitType] = await Promise.all([
        getPartnerItems(),
        getServiceTypeItems(shopId),
        getShiftTemplateItems(shopId),
        getCitizenshipItems(),
        isEdit ? getShiftLunchItems(shiftId) : getLunchItems(clientId),
        getUnitTypeItems(),
      ]);

      const lunchSchemesTmp = lunchSchemes || [];

      state.partnerItems = partners;
      state.serviceTypeItems = serviceTypes;
      state.unitTypeItems = unitType;
      state.shiftTemplateItems = shiftTemplates;
      state.citizenshipItems = citizenships;
      state.lunchSchemeItems = lunchSchemesTmp;

      if (!state.shiftId) {
        setSelectValue(ShopEntityModule.model.partnerUuid, state.partnerItems, 'partner');
      }

      if (state.serviceTypeItems[0]) {
        setSelectValue(serviceTypeId, state.serviceTypeItems, 'serviceType', state.serviceTypeItems[0]);
      }
    } finally {
      state.isLoadingItems = false;
    }
  }

  async function handleSelectShiftTemplate({ id }: SelectItem) {
    if (!id) {
      return;
    }

    try {
      state.isLoadingShiftTemplateEntity = true;

      const templateEntity = await fetchShiftTemplateInfo(id);
      if (templateEntity) {
        modifyStateByTemplate(state, templateEntity);
      }
    } finally {
      state.isLoadingShiftTemplateEntity = false;
    }
  }

  return {
    paymentStrategyItems,
    getPartnerItems,
    getServiceTypeItems,
    getUnitTypeItems,
    fetchShiftTemplateInfo,
    handleSelectShiftTemplate,
    fetchItems,
    initPartnerWatchForPaymentStrategy,
  };
});

export function findGenderItem(searchValue: string) {
  searchValue = ['', 'm', 'f'].includes(searchValue) ? searchValue : '';

  return Object.values(gendersAll).find((gender) => gender.value === searchValue) as RadioForm;
}
