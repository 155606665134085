import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-date-switcher__wrapper by-day" }
const _hoisted_2 = { class: "cp-date-switcher__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'chevron-left'],
      class: _normalizeClass(["cp-date-switcher__arrow cp-date-switcher__arrow--prev", { disabled: this.day <= this.min, 'cp-cursor-pointer': this.day > this.min }]),
      size: "lg",
      onClick: _ctx.handlePrev
    }, null, 8, ["class", "onClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.date) + " ", 1),
      (_ctx.day >= 0)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
            _createTextVNode("+")
          ])
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
            _createTextVNode("-")
          ]),
      _createTextVNode(" " + _toDisplayString(Math.abs(_ctx.day)) + " " + _toDisplayString(_ctx.text), 1)
    ]),
    _createVNode(_component_font_awesome_icon, {
      icon: ['fas', 'chevron-right'],
      class: _normalizeClass(["cp-date-switcher__arrow cp-date-switcher__arrow--next", { disabled: this.day >= this.max, 'cp-cursor-pointer': this.day < this.max }]),
      size: "lg",
      onClick: _ctx.handleNext
    }, null, 8, ["class", "onClick"])
  ]))
}