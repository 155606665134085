
import moment from 'moment/moment.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { defineComponent } from 'vue';

library.add(faChevronLeft, faChevronRight);

export default defineComponent({
  name: 'GCalendar',
  emits: ['selectValue', 'init'],
  props: {
    date: {
      type: String,
    },
    format: {
      type: String,
      default: 'DD/MM/YY',
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
  },
  data: () => ({
    dayOfWeeks: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
    selectedDay: moment().date(),
    selectedMonth: moment(),
    month: moment(),
    monthWeeks: {} as Record<string, Record<string, number>>,
    minDate: null as Date | null,
    maxDate: null as Date | null,
  }),
  watch: {
    date: {
      immediate: true,
      handler(value: string) {
        // @ts-ignore
        const { format } = this;

        let month = moment(value, format);
        if (!month.isValid()) {
          month = moment();
          this.$emit('init', month.format(format));
        }

        this.selectedMonth = month;
        this.month = month.clone();
      },
    },
    month: {
      immediate: true,
      handler() {
        const { month: date } = this;

        this.selectedDay = -1;

        const month = date.format('MM');

        const year = date.format('YYYY');

        const startOfMonth = date.startOf('month').date();

        const endOfMonth = date.endOf('month').date();

        const weeks: Record<string, Record<string, number>> = {};
        let weekOfYear, dayOfWeek;
        for (let index = startOfMonth; index <= endOfMonth; index++) {
          const tmpMoment = moment(`${index}/${month}/${year}`, 'DD/MM/YYYY');

          if (tmpMoment.isSame(this.selectedMonth)) {
            this.selectedDay = index;
          }

          weekOfYear = 'w' + tmpMoment.week();
          dayOfWeek = tmpMoment.isoWeekday();

          if (weeks[weekOfYear]) {
            weeks[weekOfYear][dayOfWeek] = index;
          } else {
            weeks[weekOfYear] = {};
            weeks[weekOfYear][dayOfWeek] = index;
          }
        }

        this.monthWeeks = weeks;
      },
    },
    'min,max': {
      immediate: true,
      handler() {
        this.minDate = this.min ? moment(this.min, this.format).toDate() : null;
        this.maxDate = this.max ? moment(this.max, this.format).toDate() : null;
      },
    },
  },
  methods: {
    next() {
      const month = this.month.add(1, 'months');
      this.month = moment(month);
    },
    prev() {
      const month = this.month.subtract(1, 'months');
      this.month = moment(month);
    },
    selectValue(day: number) {
      if (isNaN(day)) {
        return;
      }

      // @ts-ignore
      const { format } = this;

      const selectedDate = moment(this.month.set('date', day)).format(format);

      this.$emit('selectValue', selectedDate);
    },
    checkIsDisabled(dayOfMonth: number) {
      const currentDate = this.month.clone().date(dayOfMonth).startOf?.('day').toDate();
      const isExcessiveDate = this.maxDate && currentDate > this.maxDate;
      const isDeficientlyDate = this.minDate && currentDate < this.minDate;

      return currentDate && (isExcessiveDate || isDeficientlyDate);
    },
  },
});
