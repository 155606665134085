
import { defineComponent, PropType } from 'vue';

interface SwitchItem {
  id: string | number;
  checked: boolean;
  icon?: string;
  name: string;
  preview?: string;
  value: unknown;
}

export default defineComponent({
  name: 'form-switch',
  emits: ['update:modelValue', 'update'],
  props: {
    modelValue: { required: true },
    items: {
      type: Array as PropType<SwitchItem[]>,
      required: true,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    selectedValue: {
      get(): number {
        const index = this.items.findIndex((el: SwitchItem) => this.modelValue === el.value);

        return index !== -1 ? index : 1;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
        this.$emit('update', value);
      },
    },
  },
  methods: {
    getSelected(value: unknown) {
      return value == this.items[this.selectedValue].value;
    },
  },
});
