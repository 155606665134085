import { helpers, MessageProps } from '@vuelidate/validators';
import { ValidatorFn } from '@vuelidate/core';
import messages from '@/lib/validator/messages';
import validators, { ValidatorListInterface } from '@/lib/validator/validators';

interface ValidatorConfigInterface {
  name: keyof ValidatorListInterface;
  message?: string | ((params: MessageProps) => string);
  attributes?: any;
}

const validator = function validator(config: ValidatorConfigInterface) {
  const { attributes = undefined } = config;
  const VALIDATOR_FUNCTION = validators[config.name];
  const VALIDATOR = VALIDATOR_FUNCTION(attributes);
  const MESSAGE = config.message || messages[config.name];

  return MESSAGE ? helpers.withMessage(MESSAGE, VALIDATOR as ValidatorFn) : VALIDATOR;
};

export default validator;
