<template>
  <div
    ref="datePickerDefault"
    :class="[
      'cp-input-fake__wrapper cp-date-picker',
      {
        disabled: disabled,
      },
    ]"
  >
    <div class="mr-input-default__title cd-form-input-label" :class="{ 'cd-form-input-label-active': visible || viewData }">
      {{ data.title }}
    </div>

    <div class="cp-input-fake cp-input-radius-50" @click="toggleVisible">
      <span v-if="viewData" class="mr-input-fake__value">{{ viewData }}</span>
      <input type="text" style="position: absolute; top: -100000px; left: -100000px" @focus="toggleVisible" />
      <span v-if="data.placeholder && !viewData" class="mr-input-fake__placeholder">{{ data.placeholder }}</span>
      <div class="icon-calendar" v-if="!withClear || !visible"></div>
      <div class="icon-close" v-else @click="clear"></div>
    </div>

    <g-calendar
      v-if="visible"
      :date="data.value"
      :format="format"
      :min="min"
      :max="max"
      @init="init"
      @select-value="selectValue"
    />
  </div>
</template>

<script>
import GCalendar from '@/components/form/Calendar.vue';
import moment from 'moment';
import { compareDate } from '@/lib/Utils';

export default {
  components: {
    GCalendar,
  },
  emits: ['click', 'init', 'blur'],
  props: {
    data: {
      type: Object,
      default() {
        return {
          key: 'date',
          type: 'date-picker',
          title: 'С даты',
          placeholder: '',
          value: '21/02/20',
          action: 'user/auth/updateLogin',
          required: true,
          error: {
            class: '',
            message: '',
          },
          validation: {
            empty: '',
            incorrect: '',
          },
        };
      },
    },
    format: {
      type: String,
      default: 'DD/MM/YY',
    },
    disabled: {
      type: Boolean,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    withClear: {
      type: Boolean,
    },
  },
  data: () => ({
    isVisible: false,
    blockChangeVisible: false,
    initValue: '',
    isCleared: false,
  }),
  computed: {
    visible: {
      set(value) {
        if (this.blockChangeVisible) {
          return;
        }

        this.isVisible = value;

        if (!value) {
          return;
        }

        this.blockChangeVisible = true;
        setTimeout(() => {
          this.blockChangeVisible = false;
        }, 0);
      },
      get() {
        return this.isVisible;
      },
    },
    viewData() {
      return this.initValue || this.data.value;
    },
  },
  watch: {
    'data.value'() {
      this.initValue = '';
    },
    visible(isVisible) {
      if (!isVisible) {
        const [day, month, year] = this.data.value.split('/');
        this.$emit('blur', { day, month, year });
      }
    },
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.datePickerDefault;
      const target = e.target;

      this.visible = this.visible && (!el || el === target || el.contains(target));
    },
    toggleVisible() {
      if (this.disabled) {
        return;
      }

      this.visible = !this.visible;
    },
    validate(params) {
      const date = `${params.day}/${params.month}/${params.year}`;
      const { min, max } = this;

      return (!min || compareDate(date, min, this.format) > -1) && (!max || compareDate(max, date, this.format) > -1);
    },
    selectValue(value) {
      const newValue = this.stringToObject(value);

      if (!this.validate(newValue)) {
        return;
      }

      this.visible = false;
      this.$emit('click', newValue);
    },
    init(value) {
      if (this.isCleared) {
        this.isCleared = !this.isCleared;

        return;
      }

      this.initValue = value;

      this.$emit('init', this.stringToObject(value));
    },
    clear() {
      this.isCleared = true;
      this.$emit('click', { day: '', month: '', year: '' });
    },
    stringToObject(value) {
      const date = moment(value, this.format);

      return {
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YY'),
      };
    },
  },
  created() {
    document.body.addEventListener('click', this.documentClick);
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/variables';

.icon-close {
  position: absolute;
  right: 8px;
  color: $color-error;
  font-size: 10px;

  &:hover {
    color: $color-icon-primary;
  }
}
</style>
