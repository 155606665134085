import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { PageSort } from '@/lib/layouts/page/page.interface';

import { getShopShiftLogs } from '@/api/logs';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils.ts';
import { strings } from '@/lib/stringConst';

const MODULE_NAME = 'shiftLogs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class ShiftLogsModule extends PageBaseModule {
  modal = {
    title: 'Лог заказа',
    isShow: false,
  };
  shopId = '0';
  shiftId = '0';
  logsModalIsShow = false;
  isTableLoading = true;

  constructor(module: ShiftLogsModule) {
    super(module);

    const page = new PageEntity();
    this.pageSettings = page.values;
    this.pageSettings.actionPagination = 'shiftLogs/updatePage';
  }

  @Mutation
  TOGGLE_MODAL(bool: boolean) {
    this.modal.isShow = bool;
  }

  @Mutation
  SET_IS_TABLE_LOAD(bool: boolean) {
    this.isTableLoading = bool;
  }

  @Mutation
  SET_SHOP_ID(shopId: string) {
    this.shopId = shopId;
  }

  @Mutation
  SET_SHIFT_ID(shiftId: string) {
    this.shiftId = shiftId;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.customersLogsSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init(params: { shopId: string; shiftId: string }) {
    const pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    if (!pageAmountItems) {
      this.updatePageAmountItems('100');
    }

    this.pageSettings.pageAmountItems = pageAmountItems;
    await this.context.commit('SET_PAGE', 1);

    this.getShiftLogsById(params);
  }

  @Action({ rawError: true })
  async getShiftLogsById(params: { shopId: string; shiftId: string }) {
    try {
      const pageCurrent = this.pageSettings.pageCurrent || 1;
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = `filters[0][id]=clientMarketId&filters[0][value]=${params.shopId}&filters[1][id]=clientMarketShiftId&filters[1][value]=${params.shiftId}`;

      const result = await getShopShiftLogs(pageCurrent, itemsQuery, sort, filter);
      const processedTable = await this.prepareLogsTable(result);

      this.setLogsTable(processedTable);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setLogsTable(table: TableInterface) {
    this.context.commit('SET_TABLE', table);
    this.updateIsShiftLogsLoading(false);
  }

  @Action({ rawError: true })
  prepareLogsTable(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);

    return uiTable
      .removeColumn('id')
      .removeColumn('type')
      .removeColumn('clientMarketShiftId')
      .removeColumn('clientMarketId')

      .setSortableValues(sorts)

      .getTable() as any;
  }

  @Action({ rawError: true })
  updateIsShiftLogsLoading(bool: boolean) {
    this.context.commit('SET_IS_TABLE_LOAD', bool);
  }

  @Action({ rawError: true })
  setShopId(shopId: string) {
    this.context.commit('SET_SHOP_ID', shopId);
  }

  @Action({ rawError: true })
  setShiftId(shiftId: string) {
    this.context.commit('SET_SHIFT_ID', shiftId);
  }

  @Action({ rawError: true })
  showModal() {
    this.context.commit('TOGGLE_MODAL', true);
  }

  @Action({ rawError: true })
  hideModal() {
    this.context.commit('TOGGLE_MODAL', false);
    this.context.commit('SET_IS_TABLE_LOAD', true);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.sortProcessed(params);
    await this.context.commit('SET_SORT', sortProcessed);

    this.getShiftLogsById({ shopId: this.shopId, shiftId: this.shiftId });
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getShiftLogsById({ shopId: this.shopId, shiftId: this.shiftId });
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    this.getShiftLogsById({ shopId: this.shopId, shiftId: this.shiftId });
  }
}

export default getModule(ShiftLogsModule);
