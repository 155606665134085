import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { createSharedComposable } from '@vueuse/core';
import moment from 'moment';
import { minutesToTime } from '@/lib/Utils';
import { SelectItem } from '@/lib/formFactory/select.interface';
import ShopEntityModule from '@/store/shops/entity';
import UserModule from '@/store/user';
import { ShiftModalState } from '../interfaces';
import { useShiftFormDateTime } from './useShiftFormDateTime';
import { useShiftFormValidate } from './useShiftFormValidate';

export const useShiftFormComputedProps = createSharedComposable(function (state: ShiftModalState) {
  const route = useRoute();
  const { checkIsFutureDate } = useShiftFormDateTime(state);
  const { getFieldValidator } = useShiftFormValidate(state);

  const isSupervisor = computed(() => UserModule.isSupervisor);
  const isContractorOrSupervisor = computed(() => {
    const shopContractor = state.contractor || ShopEntityModule.model.contractor;
    const isCurrentPartnerContractor = !!shopContractor && shopContractor === UserModule.partner?.uuid;

    return isSupervisor.value || isCurrentPartnerContractor;
  });

  const isSubcontractorOrSupervisor = computed(() => {
    const shopContractor = ShopEntityModule.model.clientSubcontractors;
    const isCurrentPartnerSubcontractor =
      !!shopContractor && shopContractor.some((item) => item.partner.uuid === UserModule.partner?.uuid);

    return isSupervisor.value || isCurrentPartnerSubcontractor;
  });

  const isContractor = computed(() => {
    const shiftContractor = state.shiftFullEntity?.partner.uuid;
    const isCurrentPartnerContractor = !!shiftContractor && shiftContractor === UserModule.partner?.uuid;

    return isSupervisor.value || isCurrentPartnerContractor;
  });

  const marketName = computed(() => state.shiftFullEntity?.clientMarket.fullName ?? ShopEntityModule.titleEdit);

  const isEdit = computed(() => !!state.shiftId);

  const modalTitle = computed(() => {
    if (state.shiftId) {
      let editTitle = 'Заказ';

      if (state.shiftFullEntity?.start) {
        const shiftStartMoment = moment(state.shiftFullEntity?.start);
        editTitle = shiftStartMoment.format(`${editTitle} dd DD.MM.YYYY`);
      }

      return editTitle;
    }

    return 'Добавить заказ';
  });

  const marketLink = computed(() => {
    const link = `/shops/${state.shopId}/shift`;
    const isSameRoute = route && route.path === link;

    if (!state.shopId || !isEdit.value || isSameRoute) {
      return null;
    }

    return link;
  });

  const countSavedShiftEmployees = computed(() => state.shiftFullEntity?.countEmployee ?? 0);

  const isDisabledEducationLink = computed(() => {
    let hasShiftTemplate = false;
    let hasTrainingLink = false;

    if (isEdit.value) {
      hasShiftTemplate = !!state.shiftFullEntity?.shiftTemplate;
      hasTrainingLink = !!state.shiftFullEntity?.competence?.trainingWebLink;
    } else {
      hasShiftTemplate = !!state.shiftTemplateFullEntity;
      hasTrainingLink = !!state.shiftTemplateFullEntity?.competence?.trainingWebLink;
    }

    return hasShiftTemplate && hasTrainingLink;
  });

  const canChangeGender = computed(() => !state.past);
  const canEditRate = computed(() => !state.past && UserModule.userHasPermission('CAN_EDIT_CLIENT_MARKET_SHIFT_RATE'));
  const canEditLunch = computed(() => !state.past);
  const canCheckNotDevide = computed(() => !countSavedShiftEmployees.value);
  const canCheckIsAdditional = computed<boolean>(() => {
    const dateStart = Array.from(state.dates).sort().shift();

    return !state.past && !!dateStart && checkIsFutureDate(dateStart);
  });

  const isChangedRate = computed(() => {
    return (
      !getFieldValidator('rate').isInvalid &&
      Number.isFinite(state.shiftFullEntity?.rate) &&
      state.shiftFullEntity?.rate !== state.rate
    );
  });

  const isChangedExternalRate = computed(() => {
    return (
      !getFieldValidator('externalRate').isInvalid &&
      Number.isFinite(state.shiftFullEntity?.externalRate) &&
      state.shiftFullEntity?.externalRate !== state.externalRate
    );
  });

  const canUpdateFutureRates = computed(() => state.updateFuture.id && (isChangedRate.value || isChangedExternalRate.value));

  const lunchModel = computed({
    get: () => {
      const duration = Number(state.lunch);
      let isPaid = false;

      const currentLunchScheme = state.lunchSchemeItems?.find((item) => item.data.duration == duration);

      if (currentLunchScheme) {
        isPaid = currentLunchScheme.data.isPaid;
      }

      return { id: duration.toString(), value: minutesToTime(duration), data: { duration, isPaid } };
    },
    set: (selectedLunch: SelectItem<{ duration: number; isPaid: boolean }>) => {
      state.lunch = selectedLunch.data.duration;
    },
  });

  return {
    modalTitle,
    isSupervisor,
    isContractorOrSupervisor,
    isSubcontractorOrSupervisor,
    isContractor,
    marketName,
    isEdit,
    marketLink,
    canEditRate,
    canEditLunch,
    isDisabledEducationLink,
    canCheckNotDevide,
    canCheckIsAdditional,
    canChangeGender,
    lunchModel,
    isChangedRate,
    isChangedExternalRate,
    canUpdateFutureRates,
  };
});
