import { computed } from 'vue';
import { TemplateRequestModel } from '@/interfaces/models/template.interface';
import { FormData } from '@/interfaces/shared';
import { DEFAULT_HOURS } from '@/store/customers/templatesEntity';
import {
  ShiftBasePayload,
  ShiftCreatePayload,
  ShiftDatePayload,
  ShiftEditPayload,
  ShiftModalState,
  ShiftTemplatePayload,
} from '../interfaces';
import { useShiftFormComputedProps } from './useShiftFormComputedProps';

export function useShiftPayload(state: ShiftModalState) {
  const { canUpdateFutureRates, isContractorOrSupervisor } = useShiftFormComputedProps(state);

  const payloadHours = computed(() => ({
    minHours: state.minHours || 0,
    maxHours: state.maxHours === '' ? DEFAULT_HOURS : state.maxHours,
  }));

  const payloadIntervals = computed(() => {
    let [intervalStart, intervalEnd] = state.interval.split('-');
    intervalStart += ':00';
    intervalEnd += ':00';

    return {
      intervalStart,
      intervalEnd,
    };
  });

  const payloadDates = computed(() => {
    return state.dates.reduce((result, date, index: number) => {
      const key = `dates[${index}]` as keyof ShiftDatePayload;
      result[key] = date;

      return result;
    }, {} as ShiftDatePayload);
  });

  function prepareCitizenshipsPayload(fieldName: 'citizenship' | 'citizenships') {
    return state.citizenships.reduce((result, { id }, index) => {
      const key = `${fieldName}[${index}]`;
      result[key] = id;

      return result;
    }, {} as Record<string, number>);
  }

  function clearEmptyValues(payload: FormData): void {
    for (const field in payload) {
      if (payload[field] === '' || payload[field] === undefined) {
        delete payload[field];
      }
    }
  }

  function prepareBasePayload(): ShiftBasePayload {
    const payload: ShiftBasePayload = {
      ...payloadHours.value,
      ...payloadIntervals.value,
      ...prepareCitizenshipsPayload('citizenships'),
      needCountEmployees: state.needCountEmployees,
      isAdditional: state.isAdditional ? '1' : '0',
      sharing: state.sharing ? 'all' : 'none',
      notDevide: state.notDevide ? '1' : '0',
      rate: state.rate,
      partner: state.partner.id,
      lunch: state.lunch ? state.lunch : 0,
      ageFrom: state.ageFrom,
      ageTo: state.ageTo,
      hasLmk: state.hasLmk ? '1' : '0',
      isTraining: state.isTraining ? '1' : '0',
      educationLink: state.educationLink,
      gender: state.gender.value ?? '',
      info: state.info ?? '',
      unitType: state.unitType.id,
    };

    if (state.unitType.id !== '1') {
      payload.quantity = state.quantity;
      payload.price = state.price;
    }

    if (isContractorOrSupervisor.value) {
      payload.externalRate = state.externalRate;
    }

    return payload;
  }

  function prepareEmployeesPayload() {
    const payload: FormData = {};

    for (const index in state.employees) {
      const employee = state.employees[index];
      const employeePayload: FormData = {
        employee: employee.employee.id,
        statusId: employee.generalStatus,
        paymentPartner: employee.paymentPartner,
        comment: employee.comment,
      };

      if (
        employee.generalStatus === 'came_out' ||
        employee.generalStatus === 'is_planned' ||
        employee.generalStatus === 'fast_replacement'
      ) {
        if (employee.lunch !== undefined) {
          employeePayload.lunch = employee.lunch as any;
        }
        if (employee.intervalStart.hour && employee.intervalEnd.hour) {
          employeePayload.intervalStart = `${employee.intervalStart.hour}:${employee.intervalStart.minute}:00`;
          employeePayload.intervalEnd = `${employee.intervalEnd.hour}:${employee.intervalEnd.minute}:00`;
        }
        if (employee.price && employee.quantity && state.unitType.id !== '1') {
          employeePayload.price = employee.price;
          employeePayload.quantity = employee.quantity;
        }
      }

      if (typeof employee.yellowCard !== 'undefined') {
        employeePayload.yellowCard = employee.yellowCard ? 1 : 0;
      }

      const employeeEntries = Object.entries(employeePayload).map(([key, value]) => [`employees[${index}][${key}]`, value]);

      Object.assign(payload, Object.fromEntries(employeeEntries));
    }

    return payload;
  }

  function prepareCreatePayload(): ShiftCreatePayload {
    const basePayload = prepareBasePayload();
    const payload: ShiftCreatePayload = {
      ...basePayload,
      ...payloadDates.value,
      paymentStrategy: state.paymentStrategy.id,
      serviceType: state.serviceType.id,
      includeWeekdays: '1',
      includeWeekends: '1',

      shiftTemplate: state.shiftTemplate?.id,
    };

    if (state.notDevide) {
      payload.lunch = state.lunch;
    }

    clearEmptyValues(payload);

    return payload;
  }

  function prepareEditPayload(isSupervisor: boolean): ShiftEditPayload {
    const payload = prepareBasePayload() as ShiftEditPayload;

    if (state.notDevide && state.shiftFullEntity?.past && state.shiftFullEntity.lunch) {
      payload.lunch = state.shiftFullEntity.lunch;
    }

    if (isSupervisor) {
      payload.paymentStrategy = state.paymentStrategy.id;
    }

    if (canUpdateFutureRates.value) {
      payload.updateFuture = state.updateFuture.id;
    }

    Object.assign(payload, prepareEmployeesPayload());

    clearEmptyValues(payload);

    return payload;
  }

  function prepareShiftTemplatePayload(templateName: string, templateModel?: TemplateRequestModel) {
    const payload: ShiftTemplatePayload = {
      name: templateName,
      isTraining: state.isTraining ? '1' : '0',
      hasLmk: state.hasLmk ? '1' : '0',
      serviceType: state.serviceType.id,
      unitType: state.unitType.id,
      rate: state.rate,
      externalRate: state.externalRate,
      educationLink: state.educationLink,
      'age[from]': state.ageFrom || 14,
      'age[to]': state.ageTo || 100,
      ...payloadHours.value,
      ...prepareCitizenshipsPayload('citizenship'),

      vaccinationRequirement: 'not_vaccinated',
      isActive: '1',
      hasRotation: '0',
      forLk: '0',
    };

    if (state.gender.value) {
      payload.gender = state.gender.value;
    }

    if (templateModel) {
      payload.vaccinationRequirement = templateModel.vaccinationRequirement;
      payload.isActive = templateModel.isActive ? '1' : '0';
      payload.hasRotation = templateModel.hasRotation ? '1' : '0';
      payload.forLk = templateModel.forLk ? '1' : '0';
    }

    return payload;
  }

  return {
    payloadHours,
    prepareEmployeesPayload,
    prepareCreatePayload,
    prepareEditPayload,
    prepareShiftTemplatePayload,
  };
}
