import { computed } from 'vue';
import moment from 'moment';
import { getMostPopularTime } from '@/api/shop';
import { CatchFormResponse } from '@/interfaces/shared';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ShopEntityModule from '@/store/shops/entity';
import { ShiftModalState } from '../interfaces';

export function useShiftFormDateTime(state: ShiftModalState) {
  const isoDateFormat = 'YYYY-MM-DD';

  const currentMoment = moment();
  const currentDate = currentMoment.format(isoDateFormat);
  const minDate = currentMoment.clone().add(-6, 'months').format(isoDateFormat);
  const maxDate = currentMoment.clone().add(6, 'months').format(isoDateFormat);

  const timezoneOffset = computed(() => ShopEntityModule.timeZone);

  const intervalRange = computed(() => {
    const [start = '', end = ''] = state.interval.split('-');

    return { start, end };
  });

  function checkIsFutureDate(isoDate: string) {
    let isFutureDate = false;

    if (intervalRange.value['start']) {
      const startDateTime = `${isoDate} ${intervalRange.value['start']}`;

      isFutureDate = moment(startDateTime, 'YYYY-MM-DD HH:mm').utcOffset(timezoneOffset.value, true).diff(new Date()) > 0;
    }

    return isFutureDate;
  }

  async function getPopularTimeInterval(serviceTypeId: string, shopId: string) {
    if (!serviceTypeId || !shopId) {
      return;
    }

    let popularInterval = '';
    const normalizeTime = (time: string) => time.replace(/:\d{2}$/, '');

    try {
      const popularTime = await getMostPopularTime(serviceTypeId, shopId);
      const { start_time: startInterval, end_time: endInterval } = popularTime?.[0] ?? { start_time: '', end_time: '' };

      popularInterval = [normalizeTime(startInterval), normalizeTime(endInterval)].filter(Boolean).join('-');
    } catch (error) {
      const errorResponseMessage = (error as CatchFormResponse<string, string>).response.data.errors?.fields;

      if (errorResponseMessage) {
        ResponseHandlerModule.showNotify({
          message: errorResponseMessage,
          type: 'fail',
        });
      }
    }

    return popularInterval;
  }

  return {
    currentMoment,
    currentDate,
    minDate,
    maxDate,
    intervalRange,
    timezoneOffset,
    checkIsFutureDate,
    getPopularTimeInterval,
  };
}
