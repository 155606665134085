import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class EmployeesFilter {
  filterHandlers = {
    update: 'employeesFreeShiftsFilter/updateFilter',
    reset: 'employeesFreeShiftsFilter/resetFilter',
    resetState: 'employeesFreeShiftsFilter/resetFilterState',
    removeSelected: 'employeesFreeShiftsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('timeLimit');
    filter.setTitle('timeLimit', 'Свободное время');
    filter.setName('timeLimit', 'select_timeLimit');
    filter.setList('timeLimit', [
      { id: '0', value: 'Показывать всех' },
      { id: '1', value: 'Подходящие' },
      { id: '2', value: 'Нет графика' },
      { id: '3', value: 'Неполное соответствие' },
      { id: '4', value: 'График не совпадает' },
    ]);
    filter.setAction('timeLimit', 'employeesFreeShiftsFilter/updateSelect');
    filter.setCurrent('timeLimit', { id: 'full_time', value: 'Полностью свободен' });

    filter.createSearchEntity('fullName');
    filter.setTitle('fullName', 'Поиск по ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'Поиск по ФИО');
    filter.setAction('fullName', 'employeesFreeShiftsFilter/updateSearch');
    filter.setSearchEnabled('fullName', true);

    filter.createSearchEntity('maxDistance');
    filter.setTitle('maxDistance', 'Удалённость, м');
    filter.setName('maxDistance', 'search_maxDistance');
    filter.setPreviewName('maxDistance', 'Удалённость');
    filter.setClassName('maxDistance', 'col-1-row-3');
    filter.setAction('maxDistance', 'employeesFreeShiftsFilter/updateSearch');

    this.filterModel = filter.filterModel;
  }
}
