/**
 * Employees for free market shift.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './employeesFilter';
import EmployeesFreeShiftsFilter from './employeesFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';

import {
  getEmployeesShiftsList,
  addEmployeeToShift,
  deleteEmployeeFromShift,
  shiftRefuse,
  sendPushMessage,
} from '@/api/freeMarketShifts';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { PushStatus, prepareList } from '@/lib/EmployeesFreeShifts';
import ShopShiftsModule from '@/store/shops/shift';
import moment from 'moment';
import { strings } from '@/lib/stringConst';
import { ShiftEntityInterface } from '@/store/shops/shiftModalParams';
import { Checkbox } from '@/interfaces/filter.interface';
import { CommentModalParamsInterface, modalParams } from '@/store/tasks/freeMarketShifts/commentModalParams';
import { addNewComment } from '@/api/employees';
import { ShiftEmployeeStatus } from '@/lib/util/consts';

export const MODULE_NAME = 'employeesFreeShifts';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeesFreeShifts extends PageBaseModule {
  shiftId = '';
  shift: Partial<ShiftEntityInterface> = {};
  defaultFilter: string;
  filter: Filter;
  commentModalParams: CommentModalParamsInterface = modalParams;
  watchedIds: string[] = [];
  statusEnum: Record<string, string> = {
    planned: 'is_planned',
    reserve: 'reserve',
    declinedPlace: 'refused_in_this_market',
    declinedTime: 'refused_at_this_time',
  };
  isLoading = false;

  constructor(module: EmployeesFreeShifts) {
    super(module);

    const page = new PageEntity();
    page.setTitle('');
    page.values.actionPagination = 'employeesFreeShifts/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.defaultFilter = '&filters[0][id]=shiftId&filters[0][value]=';
    this.filter = getModule(EmployeesFreeShiftsFilter);
    this.filter.resetFilter();
    this.filter.setFilterName('employeesFreeShiftsFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.shiftEmployeesSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_SHIFT_ID(id: string) {
    this.shiftId = id;
  }

  @Mutation
  SET_SHIFT(shift: ShiftEntityInterface) {
    this.shift = shift;
  }

  @Mutation
  SET_WATCHED_ID(id: string) {
    this.watchedIds.push(id);
    window.localStorage.freeMarketwatchedIds = JSON.stringify(this.watchedIds);
  }

  @Mutation
  SET_WATCHED_IDS(ids: string[]) {
    this.watchedIds = ids;
  }

  @Mutation
  SET_TITLE(title: string) {
    this.pageSettings.title = title;
  }

  @Mutation
  SET_IS_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  TOGGLE_COMMENT_MODAL(payload: { isShow: boolean; employeeId: string }) {
    this.commentModalParams.isShow = payload.isShow;
    this.commentModalParams.employeeId = payload.employeeId;
  }

  @Mutation
  CHANGE_CURRENT_PAGE(pageNumber: number) {
    this.pageSettings.pageCurrent = pageNumber;
  }

  @Mutation
  CHANGE_PUSH_STATUS(payload: { employeeId: string; status: PushStatus }) {
    const table = this.pageSettings.table as TableApiInterface<{ id: string }>;
    const employee = table.rows.find((employee) => payload.employeeId === employee.id);

    if (employee) {
      employee.pushStatus = payload.status;
    }
  }

  @Action({ rawError: true })
  async init(id: string) {
    this.context.commit(
      'SET_SORT',
      window.localStorage.shiftEmployeesSort ? JSON.parse(window.localStorage.shiftEmployeesSort) : {}
    );

    this.SET_SHIFT_ID(id);
    this.SET_WATCHED_ID(id);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.getShiftByID(this.shiftId);
    await this.filter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  setFilterString() {
    const timeLimitModel = this.filter.filterSettings.filterModel.timeLimit;
    const timeLimitValue = timeLimitModel.current?.value
      ? timeLimitModel.current.id
      : (timeLimitModel.list as Checkbox[])[0].id;
    this.filter.filterSettings.filter = `filters[1][id]=timeLimit&filters[1][value]=${timeLimitValue}`;

    const maxDistanceModel = this.filter.filterSettings.filterModel.maxDistance;
    const maxDistanceValue = maxDistanceModel.value as string;
    if (maxDistanceValue?.length > 0) {
      this.filter.filterSettings.filter = `${this.filter.filterSettings.filter}&filters[2][id]=maxDistance&filters[2][value]=${maxDistanceValue}`;
    }

    const fullNameModel = this.filter.filterSettings.filterModel.fullName;
    const fullNameValue = fullNameModel.value as string;
    if (fullNameValue && fullNameValue.length > 0) {
      this.filter.filterSettings.filter = `${this.filter.filterSettings.filter}&filters[4][id]=fullName&filters[4][value]=${fullNameValue}`;
    }
  }

  @Action({ rawError: true })
  async getShiftByID(shiftId: string) {
    try {
      const marketShift = await ShopShiftsModule.getShiftInfoById(shiftId);
      await this.setShiftInfo(marketShift as ShiftEntityInterface);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setShiftInfo(shift: ShiftEntityInterface) {
    shift['title'] = `Заказ ${moment(shift.start).format('dd')} ${moment(shift.start).format('DD/MM/YYYY')}`;
    shift['timeFormatted'] = `${moment(shift.start).format('HH:mm')} - ${moment(shift.end).format('HH:mm')}`;

    const notCountEmployeeStatuses = ShopShiftsModule.notCountStatuses.filter(
      (status) => status !== ShiftEmployeeStatus.Reserve
    );

    let countEmployee = 0;
    for (const employee of Object.values(shift.shiftEmployees)) {
      if (notCountEmployeeStatuses.includes(employee.generalStatus)) {
        continue;
      }
      countEmployee += 1;
    }
    shift['countEmployee'] = countEmployee;

    this.SET_SHIFT(shift);

    const fullFormatDate = 'DD.MM.YY HH:mm';
    const shortFormatDate = 'HH:mm';

    const momentStart = moment(shift.start);
    const momentEnd = moment(shift.end);
    let endFormat = shortFormatDate;

    if (momentEnd.diff(momentStart, 'days') >= 1) {
      endFormat = fullFormatDate;
    }

    const formattedStart = momentStart.format(fullFormatDate);
    const formattedEnd = momentEnd.format(endFormat);

    const title = `Заказ №${shift.id} на ${formattedStart} - ${formattedEnd}`;
    this.SET_TITLE(title);
  }

  @Action({ rawError: true })
  async getList() {
    try {
      this.SET_IS_LOADING(true);

      await this.setFilterString();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const sort = await this.getSortForRequest();
      const filter = `${this.defaultFilter}${this.shiftId}&${this.filter.filterSettings.filter}`;

      const result = await getEmployeesShiftsList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);

      await this.context.dispatch('setList', result.table);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      throw error;
    } finally {
      this.SET_IS_LOADING(false);
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, sort));
  }

  @Action({ rawError: true })
  async updateStatusById(params: { id: string; status: string; value: boolean }) {
    if (!params.value) {
      return await this.addEmployeeToShift({ id: params.id, status: params.status });
    }

    return await this.deleteEmployeeFromShift(params.id);
  }

  @Action({ rawError: true })
  async addEmployeeToShift(params: { id: string; status: string }) {
    let isSuccessfullyRequest = false;

    try {
      const data = {
        employee: params.id,
        status: this.statusEnum[params.status],
      };
      const result = await addEmployeeToShift(this.shiftId, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        isSuccessfullyRequest = true;
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }

    return isSuccessfullyRequest;
  }

  @Action({ rawError: true })
  async deleteEmployeeFromShift(employeeId: string) {
    let isSuccessfullyRequest = false;

    try {
      const result = await deleteEmployeeFromShift(this.shiftId, employeeId);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        isSuccessfullyRequest = true;
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }

    return isSuccessfullyRequest;
  }

  @Action({ rawError: true })
  async shiftRefuse(employeeId: string) {
    try {
      const result = await shiftRefuse({
        shift: this.shiftId,
        employee: employeeId,
      });

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveComment(comment: string) {
    await addNewComment({ employee: this.commentModalParams.employeeId, text: comment });
    await this.getList();
    this.context.commit('TOGGLE_COMMENT_MODAL', false);
  }

  @Action({ rawError: true })
  async sendPushMessage(employeeId: string) {
    try {
      this.SET_IS_LOADING(true);

      await sendPushMessage(this.shiftId, employeeId);
      this.CHANGE_PUSH_STATUS({ employeeId, status: PushStatus.IsSent });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    } finally {
      this.SET_IS_LOADING(false);
    }
  }
}

export default getModule(EmployeesFreeShifts);
