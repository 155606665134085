import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = {
  ref: "filter",
  class: "cp-flex cp-flex--justify-between align-items-end"
}
const _hoisted_3 = { class: "cp-date-filter-range" }
const _hoisted_4 = {
  key: 0,
  class: "cp-date-filter"
}
const _hoisted_5 = {
  key: 1,
  class: "cp-date-filter"
}
const _hoisted_6 = { class: "cp-flex align-items-center" }
const _hoisted_7 = {
  key: 2,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_8 = { class: "cp-flex align-items-center" }
const _hoisted_9 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_date_picker_filter = _resolveComponent("date-picker-filter")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_shift_modal = _resolveComponent("shift-modal")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createVNode(_component_title_return, {
              icon: "task-sheet",
              "icon-size": "19",
              "is-return": false
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Задачи ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("form", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.model.dateStart.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(), _createBlock(_component_date_picker_filter, {
                      key: `datepicker_${_ctx.model.dateStart.key}`,
                      data: _ctx.model.dateStart,
                      onClick: _ctx.selectDateStart
                    }, null, 8, ["data", "onClick"]))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.model.dateEnd.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(), _createBlock(_component_date_picker_filter, {
                      key: `datepicker_${_ctx.model.dateEnd.key}`,
                      data: _ctx.model.dateEnd,
                      onClick: _ctx.selectDateEnd
                    }, null, 8, ["data", "onClick"]))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_app_header_main, {
              "filter-settings": _ctx.filterSettings,
              "is-to-add-hidden": true,
              onResetFilters: _ctx.resetFilters,
              onUpdateList: _ctx.updateList
            }, {
              additional: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.totalNeedEmployee), 1)
              ]),
              _: 1
            }, 8, ["filter-settings", "onResetFilters", "onUpdateList"])
          ], 512)
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 0,
            class: "cp-free-shifts__table cp-margin__t--small grow-1",
            "is-blocked": _ctx.isLoading,
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "no-data-text": _ctx.emptyBodyText,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-client_name": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.canReadClient)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "app-table-link",
                      to: { name: 'customer_edit', params: { customerId: item.client_id } },
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(value), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(value), 1)
                    ], 64)),
                (item.isTestClient)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-client_vacancy_name": _withCtx(({ value, item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: {
            name: 'vacancy_edit',
            params: { customerId: item.client_id, vacancyId: item.client_vacancy_id },
          },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-market_name": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'shop_shift', params: { shopId: item.market_id } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(`${item.market_code},  ${value}`), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestMarket)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-need": _withCtx(({ value, item }) => [
              _createElementVNode("span", {
                class: _normalizeClass({ 'app-table-link': _ctx.isFuture(item.start) }),
                onClick: ($event: any) => (_ctx.toEdit(item))
              }, _toDisplayString(`${value} исп.`), 11, _hoisted_10)
            ]),
            "item-start": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: "app-table-link",
                onClick: ($event: any) => (_ctx.openShift(item))
              }, [
                (item.start)
                  ? (_openBlock(), _createBlock(_component_text_datetime, {
                      key: 0,
                      value: item.start,
                      "format-to": "DD.MM.YYYY H:mm"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" - "),
                (item.end)
                  ? (_openBlock(), _createBlock(_component_text_datetime, {
                      key: 1,
                      value: item.end,
                      "format-to": "H:mm"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_11)
            ]),
            "item-partner_name": _withCtx(({ item, value }) => [
              (_ctx.userCanEditPartner)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "app-table-link",
                    to: { name: 'partner_edit', params: { partnerId: item.partner_uuid } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(value), 1)
                  ], 64))
            ]),
            _: 1
          }, 8, ["is-blocked", "headers", "items", "page", "total-pages", "total-items", "page-items", "no-data-text", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_gui_loader, {
            key: 1,
            absoluteCenter: ""
          }))
        : _createCommentVNode("", true),
      (_ctx.isShowShiftModal)
        ? (_openBlock(), _createBlock(_component_shift_modal, {
            key: 2,
            "shop-id": _ctx.shopId,
            "service-type-id": _ctx.serviceTypeId,
            "shift-id": _ctx.shiftIdForModal,
            onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowShiftModal = false))
          }, null, 8, ["shop-id", "service-type-id", "shift-id"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}