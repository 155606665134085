
import { PropType, defineComponent } from 'vue';
import TimePickerComponent from './_component.vue';

export interface PickerTime {
  hours: string;
  minutes: string;
}

export default defineComponent({
  name: 'form-time-picker',
  emits: ['update:modelValue', 'update'],
  props: {
    hoursItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    minutesItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: String,
      default: '',
    },
    half: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    modalDirection: {
      type: String as PropType<'top' | 'top-left' | 'right'>,
      default: 'right',
    },
  },
  data() {
    return {
      model: this.modelValue,
      value: this.modelValue,
      visible: false,
    };
  },
  computed: {
    modifyValue(): Partial<PickerTime> {
      const value = this.value.replace(/\s/g, '');

      if (value.length === 5) {
        const result = value.split(':');

        return {
          hours: result[0],
          minutes: result[1],
        };
      }

      return {};
    },
  },
  methods: {
    save() {
      const result = this.model.split(':');
      this.value = this.model;
      this.visible = false;

      this.$emit('update:modelValue', this.value);
      this.$emit('update', {
        value: this.value,
        hour: result[0],
        minute: result[1],
      });
    },
    documentClick(e: { target: EventTarget | null }) {
      const el = this.$refs.timePicker as HTMLElement;
      const target = e.target;

      if (this.visible && el && el !== target && !el.contains(target as Node)) {
        this.visible = false;
      }
    },
  },
  mounted() {
    document.body.addEventListener('click', this.documentClick);
  },
  components: {
    TimePickerComponent,
  },
});
