import { reactive } from 'vue';
import moment from 'moment';
import { getClientShiftById } from '@/api/shop';
import { TemplateRequestModel } from '@/interfaces/models/template.interface';
import { gendersAll } from '@/lib/Genders';
import { deepFreeze } from '@/lib/Utils';
import ShopEntityModule from '@/store/shops/entity';
import { ShiftEntityInterface } from '@/store/shops/shiftModalParams';
import { ShiftModalProps, ShiftModalState } from '../interfaces';
import { useShiftEmployeeList } from './useShiftEmployeeList';
import { defaultNewShiftTemplate, findGenderItem, updateFutureItems } from './useShiftFormSelectItems';
import { useShiftFormComputedProps } from './useShiftFormComputedProps';

export function createDefaultState(props: ShiftModalProps): ShiftModalState {
  const state: ShiftModalState = reactive({
    partner: { id: '', value: 'Без партнёра', isSupervisor: false },
    paymentStrategy: { id: '', value: '' },
    needCountEmployees: '',
    shiftTemplate: { id: '', value: 'Выберите шаблон' },
    serviceType: { id: '', value: '' },
    unitType: { id: '1', value: 'час' },
    quantity: '',
    price: '',
    rate: '',
    externalRate: '',
    gender: findGenderItem(''),
    ageFrom: '',
    ageTo: '',
    minHours: '',
    maxHours: '',
    hasLmk: false,
    isTraining: false,
    educationLink: '',
    dates: props.defaultDate ? [props.defaultDate] : [],
    lunch: '',
    interval: '06:00-15:00',
    citizenships: [],
    isAdditional: false,
    sharing: ShopEntityModule.model.sharing === 'all',
    notDevide: false,
    info: '',
    updateFuture: updateFutureItems[0],

    shiftTemplateFullEntity: null,
    shiftFullEntity: null,

    employees: [],
    recommendedEmployees: [],
    partnerItems: [],
    paymentStrategyItems: [],
    serviceTypeItems: [],
    unitTypeItems: [],
    shiftTemplateItems: [],
    shiftTemplateEntitiesLoaded: {},
    citizenshipItems: [],
    lunchSchemeItems: [],

    isLoadingItems: false,
    isLoadingShiftTemplateEntity: false,
    isShiftSaving: false,

    contractor: '',
    shopId: props.shopId || '',
    serviceTypeId: props.serviceTypeId ?? '',
    unitTypeId: props.unitTypeId ?? '',
    shiftId: props.shiftEntity?.id.toString() || props.shiftId || '',
    past: false,
    uuid: '',
  });

  const { isContractorOrSupervisor } = useShiftFormComputedProps(state);
  if (isContractorOrSupervisor.value) {
    state.shiftTemplate = defaultNewShiftTemplate;
  }

  return state;
}

export async function getShiftById(id: string) {
  const shiftResponse = await getClientShiftById(id);

  return deepFreeze(shiftResponse);
}

export function modifyStateByShift(state: ShiftModalState, shift: ShiftEntityInterface) {
  state.shiftFullEntity = shift;
  state.partner = { id: shift.partner.uuid, value: shift.partner.legalName };
  state.shopId = shift.clientMarket.id.toString();
  state.contractor = shift.clientMarket.client?.contractor ?? '';
  state.serviceTypeId = shift.serviceType.id.toString();
  state.unitTypeId = shift.unitType.id.toString();
  state.serviceType = { id: shift.serviceType.id.toString(), value: shift.serviceType.name };
  state.unitType = { id: shift.unitType.id.toString(), value: shift.unitType.shortName };
  state.quantity = shift.quantity;
  state.price = shift.price;

  state.paymentStrategy = { id: shift.paymentStrategy.type, value: shift.paymentStrategy.humanType };
  state.needCountEmployees = shift.needCount;
  state.rate = shift.rate;
  state.externalRate = shift.externalRate;
  state.citizenships = (shift.citizenship ?? []).map(({ id, name }) => ({
    id,
    name,
    value: name,
    checked: false,
  }));

  if (shift.shiftTemplate) {
    state.shiftTemplate = { id: shift.shiftTemplate.id.toString(), value: shift.shiftTemplate.name };
  }

  const shiftGender = gendersAll.find(({ value }) => (value ?? '') === shift.gender);
  if (shiftGender) {
    state.gender = shiftGender;
  }

  state.dates = [shift.start];
  state.partner = { id: shift.partner.uuid, value: shift.partner.legalName };
  state.ageFrom = shift.age.from;
  state.ageTo = shift.age.to;
  state.minHours = shift.minHours;
  state.maxHours = shift.maxHours;
  state.hasLmk = shift.hasLmk;
  state.isTraining = shift.training;
  state.educationLink = shift.competence?.trainingWebLink ?? '';
  state.lunch = shift.lunch ?? 0;

  const intervalStart = moment(shift.start).format('HH:mm');
  const intervalEnd = moment(shift.end).format('HH:mm');
  state.interval = `${intervalStart}-${intervalEnd}`;

  state.isAdditional = shift.isAdditional;
  state.sharing = shift.sharing === 'all';
  state.notDevide = shift.notDevide;

  state.past = shift.past;

  const { prepareEmployee } = useShiftEmployeeList(state);
  state.employees = shift.shiftEmployees.map(prepareEmployee);
  state.uuid = shift.uuid;

  if (!state.past) {
    state.recommendedEmployees = shift.recommendedEmployees;
  }
}

export function modifyStateByTemplate(state: ShiftModalState, template: TemplateRequestModel) {
  state.shiftTemplateFullEntity = template;

  state.rate = template.rate;
  state.externalRate = template.externalRate;
  state.educationLink = template.competence?.trainingWebLink ?? '';
  state.ageFrom = template.age.from;
  state.ageTo = template.age.to;
  state.minHours = template.minHours;
  state.maxHours = template.maxHours;
  state.hasLmk = template.hasLmk;
  state.isTraining = template.isTraining;
  state.gender = findGenderItem(template.gender);
  state.serviceType = { id: template.serviceType.id.toString(), value: template.serviceType.name };
  state.unitType = { id: template.unitType.id.toString(), value: template.unitType.shortName };
  state.citizenships = (template.citizenship ?? []).map(({ id, name }) => ({
    id,
    name,
    value: name,
    checked: true,
  }));

  state.info = template.info;
}
