
import { defineComponent, PropType, nextTick } from 'vue';
import FilterModule from '@/store/modules/filter';
import { FilterPreviewEntryInterface } from '@/interfaces/filterPreviewEntry.interface';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);
const component = defineComponent({
  name: 'FilterPreview',
  emits: ['updateList', 'click-item', 'removePreviewTag'],
  props: {
    filterPreview: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    action: {
      type: String,
    },
  },
  data: () => ({
    filterPreviewsProcessed: {},
    hasHiddenPreviews: false,
  }),
  computed: {
    isShow(): boolean {
      return FilterModule.modal.isShow;
    },
  },
  watch: {
    async filterPreview(value: Record<string, unknown>) {
      if (!value) {
        return;
      }

      await this.setSortedPreviews(value);
      await nextTick(this.hidePreviews);
    },
  },
  methods: {
    async removeSelected(key: string) {
      this.$emit('removePreviewTag', key);
      if (this.action) {
        await this.$store.dispatch(this.action, key);
        this.$emit('updateList');
      }
    },
    setSortedPreviews(previews: Record<string, unknown>) {
      let values = Object.entries(previews);
      values = values.sort(this.sortCallback as any);

      this.filterPreviewsProcessed = Object.fromEntries(values);
    },
    hidePreviews() {
      const previewWrapper = this.$el as HTMLElement;
      const maxWidth = previewWrapper.offsetWidth - 65;

      const previews = document.getElementsByClassName('mr-filter-preview');
      const previewsArray = Array.from(previews) as HTMLElement[];

      previewsArray.forEach((item) => {
        if (item.offsetLeft + item.offsetWidth > maxWidth) {
          this.hasHiddenPreviews = true;
          item.classList.add('hidden');

          return;
        }
        if (item.classList.contains('hidden')) {
          item.classList.remove('hidden');
        }
        this.hasHiddenPreviews = false;
      });
    },
    sortCallback(a: FilterPreviewEntryInterface[], b: FilterPreviewEntryInterface[]) {
      return Number(a[1].iterable) - Number(b[1].iterable);
    },
  },
});

export default component;
