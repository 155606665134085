
import { ref } from 'vue';
import { Component, Vue } from '@/lib/decorator';
import TitlePage from '@/components/Title.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import DateFilter from './_dateFilter.vue';
import FormSelect from '@/components/form/Select/index.vue';

import { EMPTY_BODY_BY_CRITERIA } from '@/lib/util/consts';
import UserModule from '@/store/user';
import FreeMarketShifts from '@/store/tasks/freeMarketShifts';
import EmployeesFreeShifts from '@/store/tasks/freeMarketShifts/employees';
import { TableInterface, TableRow } from '@/lib/layouts/page/table.interface';
import { EventBus } from '@/lib/event-bus';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TasksEntityModule from '@/store/tasks/entity';
import { getPlural } from '@/lib/Utils';
import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import DatePickerFilter from '@/components/form/DatePickerFilter.vue';
import { GuiLoader } from '@library/gigant_ui';
import moment from 'moment';
import ShiftModal from '@/views/shops/shift/form/shiftModal.vue';
import { FreeMarketShift } from '@/interfaces/shift.interface';

@Component({
  components: {
    AppCol,
    AppRow,
    TextDatetime,
    AppTable,
    TabsNav,
    TitlePage,
    AppHeaderMain,
    DateFilter,
    DatePickerFilter,
    FormSelect,
    TitleReturn,
    GuiLoader,
    ShiftModal,
  },
})
export default class FreeMarketShiftsMain extends Vue {
  filter = ref(null);

  isShowShiftModal = false;
  shopId?: string;
  serviceTypeId?: string;
  shiftIdForModal?: string;

  get tabsNav() {
    return TasksEntityModule.tabsNav;
  }

  get currentTab() {
    return TasksEntityModule.tabsNav.freeMarketShifts?.id;
  }

  get settings() {
    return FreeMarketShifts.pageSettings;
  }

  get totalNeedEmployee() {
    const tableInfo = this.settings.table as TableInterface;
    const totalNeed =
      tableInfo.additionalInfo && tableInfo.additionalInfo.totalNeed ? tableInfo.additionalInfo.totalNeed : 0;

    return `${getPlural(totalNeed, ['нужен', 'нужно', 'нужно'])}
        ${totalNeed}
        ${getPlural(totalNeed, ['исполнитель', 'исполнителя', 'исполнителей'])}`;
  }

  get watchedIds() {
    return EmployeesFreeShifts.watchedIds;
  }

  get filterSettings() {
    return FreeMarketShifts.filter.filterSettings;
  }

  get isLoading(): boolean {
    return FreeMarketShifts.isLoading;
  }

  get userCanEditPartner(): boolean {
    return UserModule.userHasPermission('CAN_EDIT_PARTNER');
  }

  get canReadClient(): boolean {
    return UserModule.userHasPermission('CAN_READ_CLIENT');
  }

  get model(): Record<string, Record<string | number, string | number | Record<string, string | number | boolean>>> {
    return FreeMarketShifts.modalParams.dateRange.model as Record<
      string,
      Record<string | number, string | number | Record<string, string | number | boolean>>
    >;
  }

  get emptyBodyText(): string {
    return EMPTY_BODY_BY_CRITERIA;
  }

  async resetFilters() {
    await FreeMarketShifts.setPageToBegin();
    await FreeMarketShifts.clearSort();
    await FreeMarketShifts.filter.resetFilter();
    await FreeMarketShifts.getList();
  }

  selectDateEnd(date: { day: string; month: string; year: string }): void {
    FreeMarketShifts.updateDateEnd(`${date.day}/${date.month}/${date.year}`);
  }

  selectDateStart(date: { day: string; month: string; year: string }): void {
    FreeMarketShifts.updateDateStart(`${date.day}/${date.month}/${date.year}`);
  }

  async updateList() {
    await FreeMarketShifts.setPageToBegin();
    await FreeMarketShifts.filter.updateFilter();
    await FreeMarketShifts.getList();
  }

  openShift(item: FreeMarketShift) {
    this.shopId = item.market_id?.toString();
    this.serviceTypeId = item.service_type_id?.toString();
    this.shiftIdForModal = item.shift_id.toString();
    this.isShowShiftModal = true;
  }

  sort(header: { id: string; sort: { value: string } }) {
    FreeMarketShifts.sort({ field: header.id, sort: header.sort.value || '' });
  }

  toEdit(item: TableRow) {
    if (!this.isFuture(item.start)) {
      return;
    }
    this.$router.push({ name: 'free_market_shifts_edit', params: { shiftId: item.shift_id } });
  }

  isFuture(date: string) {
    return moment(date) > moment().subtract(12, 'h');
  }

  selectAmount(value: string) {
    FreeMarketShifts.updatePageAmountItems(value);
  }

  async initServiceTypes() {
    const result = await FreeMarketShifts.initServiceTypes();
    FreeMarketShifts.filter.updateSelectList({ key: 'serviceTypeId', list: result });
  }

  savedEditShift() {
    FreeMarketShifts.getList();
  }

  async mounted() {
    await TasksEntityModule.initTabsItems();
    await FreeMarketShifts.init();
    await this.initServiceTypes();

    EventBus.$on('savedShift', this.savedEditShift);
  }

  unmounted() {
    EventBus.$off('savedShift', this.savedEditShift);
  }
}
