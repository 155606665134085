export const genders = [
  { id: 1, name: 'Мужской', value: 'm' },
  { id: 2, name: 'Женский', value: 'f' },
];

export const gendersAll = [
  { id: 1, name: 'Мужской', value: 'm' },
  { id: 2, name: 'Женский', value: 'f' },
  { id: 3, name: 'Не важно', value: '' },
];
