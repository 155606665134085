
import { defineComponent } from 'vue';
import IconFont from '@/components/icons/IconFont.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';

const TitleReturn = defineComponent({
  emits: ['click', 'toAdd'],
  components: {
    IconFont,
    ArrowReturn,
  },
  props: {
    iconSize: {
      type: String,
    },
    icon: {
      type: String,
    },
    isAdd: {
      type: Boolean,
    },
    addTooltip: {
      type: String,
    },
    isReturn: {
      type: Boolean,
      default: true,
    },
    totalItems: {
      type: Number,
    },
  },
  methods: {
    returnBefore() {
      this.$emit('click');
    },

    toAdd() {
      this.$emit('toAdd');
    },
  },
});

export default TitleReturn;
