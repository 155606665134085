
import { defineComponent } from 'vue';
import { dateFormat } from '@/lib/Utils';

export default defineComponent({
  name: 'TextDatetime',
  props: {
    value: {
      required: true,
      type: String,
    },
    formatFrom: {
      type: String,
      default: '',
    },
    formatTo: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  methods: { dateFormat },
});
