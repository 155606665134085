import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class PayoutsFilter {
  filterHandlers = {
    update: 'freeMarketShiftsFilter/updateFilter',
    reset: 'freeMarketShiftsFilter/resetFilter',
    resetState: 'freeMarketShiftsFilter/resetFilterState',
    removeSelected: 'freeMarketShiftsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createMultiSelectEntity('clients');
    filter.setTitle('clients', 'По клиенту');
    filter.setName('clients', 'multi_select_clients');
    filter.setPreviewName('clients', 'Клиенты');
    filter.setInitAction('clients', 'freeMarketShifts/initClientsSelect');
    filter.setAction('clients', 'freeMarketShiftsFilter/updateMultiSelect');
    filter.setIconClassName('clients', 'icon-clients');
    filter.setClearAction('clients', 'freeMarketShiftsFilter/resetMultiSelect');
    filter.setClassName('clients', 'col-1-row-1');
    filter.setSearchEnabled('clients', true);

    filter.createSelectEntity('serviceTypeId');
    filter.setTitle('serviceTypeId', 'По типу услуги');
    filter.setName('serviceTypeId', 'select_service_type');
    filter.setPreviewName('serviceTypeId', 'Тип');
    filter.setInitAction('serviceTypeId', 'freeMarketShifts/initServiceTypes');
    filter.setAction('serviceTypeId', 'freeMarketShiftsFilter/updateSelect');
    filter.setIconClassName('serviceTypeId', 'icon-briefcase');
    filter.setClearAction('serviceTypeId', 'freeMarketShiftsFilter/resetSelect');
    filter.setClassName('serviceTypeId', 'col-2-row-1');
    filter.setSearchEnabled('serviceTypeId', true);

    filter.createSearchEntity('fullMarketName');
    filter.setPlaceholder('fullMarketName', 'По объекту');
    filter.setName('fullMarketName', 'search_markets');
    filter.setPreviewName('fullMarketName', 'Объекты');
    filter.setIconClassName('fullMarketName', 'icon-store');
    filter.setClassName('fullMarketName', 'col-1-row-2');
    filter.setSearchEnabled('fullMarketName', true);
    filter.setAction('fullMarketName', 'freeMarketShiftsFilter/updateSearch');

    filter.createMultiSelectEntity('regions');
    filter.setTitle('regions', 'По региону');
    filter.setName('regions', 'multi_select_regions');
    filter.setPreviewName('regions', 'Регион');
    filter.setInitAction('regions', 'freeMarketShifts/initRegionsSelect');
    filter.setIconClassName('regions', 'icon-compass');
    filter.setAction('regions', 'freeMarketShiftsFilter/updateMultiSelect');
    filter.setClassName('regions', 'col-2-row-2');
    filter.setSearchEnabled('regions', true);

    filter.createMultiSelectEntity('partners');
    filter.setTitle('partners', 'По партнёру');
    filter.setName('partners', 'multi_select_partners');
    filter.setPreviewName('partners', 'Партнёр');
    filter.setInitAction('partners', 'freeMarketShifts/initPartnersSelect');
    filter.setIconClassName('partners', 'icon-partners');
    filter.setAction('partners', 'freeMarketShiftsFilter/updateMultiSelect');
    filter.setClassName('partners', 'col-1-row-3');
    filter.setSearchEnabled('partners', true);

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'freeMarketShiftsFilter/updateCheckbox');
    filter.setIconClassName('isNotTest', 'icon-is-active');
    filter.setClassName('isNotTest', 'col-1-row-4');

    filter.createCheckboxEntity('withoutTraining');
    filter.setTitle('withoutTraining', 'Без обучения');
    filter.setName('withoutTraining', 'checkbox_withoutTraining');
    filter.setPreviewName('withoutTraining', 'Без обучения');
    filter.setIgnoreNull('withoutTraining', true);
    filter.setListElement('withoutTraining', {
      id: 'withoutTraining',
      name: 'Без обучения',
      checked: false,
    });
    filter.setAction('withoutTraining', 'freeMarketShiftsFilter/updateCheckbox');
    filter.setIconClassName('withoutTraining', 'icon-is-active');
    filter.setClassName('withoutTraining', 'col-2-row-4');

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partners', !isSupervisor);
      },
      { immediate: true }
    );

    this.filterModel = filter.filterModel;
  }
}
