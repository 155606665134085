import moment from 'moment';
import { computed, Ref } from 'vue';
import { searchShiftEmployee } from '@/api/shop';
import { CatchFormResponse } from '@/interfaces/shared';
import { EmployeeSearchItem } from '@/interfaces/shift.interface';
import { PaymentStrategies } from '@/lib/PaymentStrategies';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { strings } from '@/lib/stringConst';
import { GIGANT_INN, ShiftEmployeeStatus, ShiftEmployeeStatusTitles } from '@/lib/util/consts';
import { debounce } from '@/lib/util/helpers';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { PartnerOption } from '@/store/shops/shift';
import { NewShiftEmployeeInterface, ShiftEmployeeInterface } from '@/store/shops/shiftModalParams';
import UserModule from '@/store/user';
import { ShiftEmployeeSearchSelected, ShiftModalState } from '../interfaces';

export function useShiftEmployeeList(state: ShiftModalState) {
  const gigantPartner = computed(() => state.partnerItems.find(({ inn }) => inn === GIGANT_INN));

  const statusesByTime = {
    default: {
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      reserve: 'Резерв',
    },
    beforeShift72hours: {
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    beforeShift24hours: {
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    beforeShift12hours: {
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    beforeShift2hours: {
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    shiftStarted: {
      did_not_go_out: 'Не вышел',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
    },
    shiftEnded: {
      came_out: 'Вышел',
      did_not_go_out: 'Не вышел',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
    },
  };

  const debouncedSearchEmployee = debounce(searchEmployee, 600);

  async function searchEmployee(
    searchString: string,
    searchState: {
      isLoading: Ref<boolean>;
      hideAutocomplete: Ref<boolean>;
      foundEmployees: Ref<EmployeeSearchItem[]>;
    }
  ) {
    const shiftId = state.shiftFullEntity?.id;

    if (!searchString.length || !shiftId) {
      return;
    }

    try {
      searchState.hideAutocomplete.value = false;
      searchState.isLoading.value = true;

      const result = await searchShiftEmployee(searchString, shiftId);
      const addedEmployeeIds = Object.values(state.employees).map((employee) => employee.employee.id.toString());

      searchState.foundEmployees.value = result
        .filter((item: EmployeeSearchItem) => !addedEmployeeIds.includes(item.id.toString()))
        .map(prepareSearchItem);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse)?.response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      searchState.isLoading.value = false;
    }
  }

  function prepareStatuses() {
    const shift = state.shiftFullEntity;

    if (!shift) {
      return [];
    }

    const nowDate = moment();
    const endDate = moment(shift.end).utcOffset(shift.clientMarket.timeZone, true);
    const startDate = moment(shift.start).utcOffset(shift.clientMarket.timeZone, true);

    const checkIsStartEarlierThanHours = (hours: number): boolean => startDate < nowDate.clone().add(hours, 'hours');
    const getStatuses = (key: keyof typeof statusesByTime) => {
      return Object.entries(statusesByTime[key]).map(([id, value]) => ({ id, value }));
    };

    switch (true) {
      case endDate < nowDate:
        return getStatuses('shiftEnded');
      case startDate < nowDate:
        return getStatuses('shiftStarted');
      case checkIsStartEarlierThanHours(2):
        return getStatuses('beforeShift2hours');
      case checkIsStartEarlierThanHours(12):
        return getStatuses('beforeShift12hours');
      case checkIsStartEarlierThanHours(24):
        return getStatuses('beforeShift24hours');
      case checkIsStartEarlierThanHours(72):
        return getStatuses('beforeShift72hours');
      default:
        return getStatuses('default');
    }
  }

  function prepareSearchItem(employeeItem: EmployeeSearchItem) {
    const itemMarkup = prepareSearchItemMarkup(employeeItem);

    const searchItem: EmployeeSearchItem = {
      ...employeeItem,
      itemMarkup,
    };

    const isAllowEmployeeProfileView = UserModule.isSupervisor || employeeItem.partner.uuid === UserModule.partner?.uuid;

    if (isAllowEmployeeProfileView) {
      searchItem.linkHtml = ` <a href="/employees/${employeeItem.id}" target="_blank">посмотреть профиль&#8594;</a>`;
    }

    return searchItem;
  }

  function prepareSearchItemMarkup(employeeItem: EmployeeSearchItem) {
    const shareBadge = '<span class="icon-share cp-text__text-primary" title="Общий исполнитель"></span>';
    const testBadge = '<span class="cp-badge cp-badge_test cp-margin__l--3">Test</span>';
    const title = [employeeItem.fullName];

    if (employeeItem.sharing !== 'none') {
      title.push(shareBadge);
    }

    if (employeeItem.isTest) {
      title.push(testBadge);
    }

    return `<span style="gap: 5px" class="cp-flex cp-flex--align-center">${title.join(' ')}</span>`;
  }

  function getOverworkTooltip(employee: NewShiftEmployeeInterface): string | null {
    const shiftEmployee = employee.employee;
    const isCameOut = [ShiftEmployeeStatus.CameOut, ShiftEmployeeStatus.FastReplacement].includes(
      employee.generalStatus as ShiftEmployeeStatus
    );

    const isOverworkedEmployee =
      isCameOut && !!shiftEmployee.calendarHours && (shiftEmployee.workHours ?? 0) >= shiftEmployee.calendarHours;

    return isOverworkedEmployee ? 'Выработка превышена' : null;
  }

  function getInterval(dateString: string) {
    const date = new Date(dateString);
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return { hour, minute };
  }

  function prepareEmployee(shiftEmployee: ShiftEmployeeInterface): NewShiftEmployeeInterface {
    let icon = 'smile-neutral';
    icon = shiftEmployee.employee.marketFavorite === 'is_liked' ? 'smile-like' : icon;
    icon = shiftEmployee.employee.marketFavorite === 'is_favourite' ? 'smile-love' : icon;
    icon = shiftEmployee.employee.marketFavorite === 'is_hated' ? 'smile-hated' : icon;
    icon = shiftEmployee.isAutomatic ? `${icon}-a` : icon;

    return ({
      isNew: true,
      id: shiftEmployee.id.toString(),
      name: shiftEmployee.employee.fullName,
      value: shiftEmployee.employee.fullName,
      statusId: shiftEmployee.generalStatus,
      generalStatus: shiftEmployee.generalStatus as keyof typeof ShiftEmployeeStatusTitles,
      paymentPartner: shiftEmployee.paymentPartner.uuid,
      paymentPartnerName: shiftEmployee.paymentPartner.legalName,
      intervalStart: getInterval(shiftEmployee.start),
      intervalEnd: getInterval(shiftEmployee.end),

      end: shiftEmployee.end,
      start: shiftEmployee.start,
      workHours: '',
      late: '',
      comment: shiftEmployee.comment,
      isAutomatic: shiftEmployee.isAutomatic,
      employee: {
        id: shiftEmployee.employee.id.toString(),
        fullName: shiftEmployee.employee.fullName,
        rate: shiftEmployee.rate,
        isSelfEmployed: (shiftEmployee.employee as any).isSelfEmployed,
        calendarHours: (shiftEmployee.employee as any).calendarHours,
        workHours: (shiftEmployee.employee as any).workHours,
        partner: (shiftEmployee.employee as any).partner,
        sharing: (shiftEmployee.employee as any).sharing,
        isTest: (shiftEmployee.employee as any).isTest,
      },
      rate: shiftEmployee.rate,
      icon,
      lunch: shiftEmployee.lunch,
      isShowInfo: false,
      checkedIn: shiftEmployee.checkedIn,
      checkedOut: shiftEmployee.checkedOut,
      isStartIn12Hours: false,
      belongToPartner: shiftEmployee.belongToPartner,
      quantity: shiftEmployee.quantity,
      price: shiftEmployee.price,
    } as unknown) as NewShiftEmployeeInterface;
  }

  function getPaymentPartnerByStrategy(params: {
    shiftPartner: PartnerOption;
    employeePartner: PartnerOption;
    paymentStrategy: string;
  }) {
    let partner: PartnerOption | undefined;

    switch (params.paymentStrategy) {
      case PaymentStrategies.PartnerPays:
        partner = params.shiftPartner;
        break;
      case PaymentStrategies.GigantPays:
        partner = gigantPartner.value;
        break;
      case PaymentStrategies.PartnerAndGigantPays:
        partner = params.shiftPartner.id === params.employeePartner.id ? params.employeePartner : gigantPartner.value;
    }

    return partner;
  }

  function selectEmployee(selectItem: SelectItem<ShiftEmployeeSearchSelected>) {
    if (!selectItem.id) {
      return;
    }

    const [startTime, endTime] = state.interval.split('-');
    const [startHours, startMinutes] = startTime.split(':');
    const [endHours, endMinutes] = endTime.split(':');

    const selectedEmployee = selectItem.data;
    const isAdded = state.employees.find((item) => item.id.toString() === selectedEmployee.id.toString());

    if (isAdded) {
      return;
    }

    let generalStatus: NewShiftEmployeeInterface['generalStatus'] = state.past ? 'came_out' : 'is_planned';

    const plannedEmployees = state.employees.filter((obj) => {
      return obj.statusId == 'is_planned';
    });

    if (!state.past && Number(state.needCountEmployees) <= plannedEmployees.length) {
      generalStatus = 'reserve';
    }

    const paymentPartner = getPaymentPartnerByStrategy({
      shiftPartner: { id: state.partner?.id, value: state.partner.value },
      employeePartner: { id: selectItem.data.partner.uuid, value: selectItem.data.partner.uuid },
      paymentStrategy: state.paymentStrategy.id,
    });

    const employee = {
      isNew: true,
      id: selectedEmployee.id.toString(),
      name: selectedEmployee.fullName,
      value: selectedEmployee.fullName,
      statusId: generalStatus,
      generalStatus: generalStatus,
      paymentPartner: paymentPartner?.id,
      paymentPartnerName: paymentPartner?.value,
      intervalStart: {
        hour: startHours,
        minute: startMinutes,
      },
      intervalEnd: {
        hour: endHours,
        minute: endMinutes,
      },
      end: '',
      start: '',
      workHours: '',
      late: '',
      comment: '',
      isAutomatic: false,
      employee: {
        id: selectedEmployee.id.toString(),
        fullName: selectedEmployee.fullName,
        rate: +state.rate,
        isSelfEmployed: selectedEmployee.isSelfEmployed,
        calendarHours: selectedEmployee.calendarHours,
        workHours: selectedEmployee.workHours,
        partner: selectedEmployee.partner,
        sharing: selectedEmployee.sharing,
      },
      rate: +state.rate,
      icon: 'smile-neutral',
      lunch: +state.lunch,
      isShowInfo: false,
      isStartIn12Hours: false,
    };

    state.employees.push((employee as unknown) as NewShiftEmployeeInterface);
  }

  function deleteEmployee(id: number | string) {
    const employeeIndex = state.employees.findIndex((employee) => employee.employee.id.toString() === id.toString());

    if (employeeIndex >= 0) {
      state.employees.splice(employeeIndex, 1);
    }
  }

  return {
    searchEmployee,
    debouncedSearchEmployee,
    deleteEmployee,
    selectEmployee,
    prepareEmployee,
    prepareStatuses,
    getOverworkTooltip,
    getPaymentPartnerByStrategy,
    gigantPartner,
  };
}
