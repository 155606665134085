import { AssemblerUrl } from '@/lib/UtilsApi';
import API from '@/config/api-routers';
import { makeGetRequest, makeFormRequest, makeGetFileRequest } from '@/api/requests';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

/**
 * Get shops list for revise
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_SHIFT_EMPLOYEE_CHECKUP
 */
export const getReviseShopsList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.revise.getShopList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get shops total for revise
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_SHIFT_EMPLOYEE_CHECKUP
 */
export const getReviseShopsTotal = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.revise.getShopTotal);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get revise report list
 *
 * @param id
 * @param sort
 * @param filter
 *
 * @access CAN_READ_SHIFT_EMPLOYEE_CHECKUP
 */
export const getReportReviseList = async (id: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.revise.getShopReport.replace('$1', id));
  const url = assemblerUrl.addFilter(filter).addSort(sort).getUrl();
  const result = await makeGetRequest<{
    result: {
      calendar: {
        employeeId: number;
        firstName: string;
        lastName: string;
        middleName: string;
        serviceTypeId: number;
        serviceTypeName: string;
        dates: Record<
          string,
          {
            clientMarketShiftEmployeeId: number;
            date: string;
            status: string;
            time: number;
          }
        >;
      }[];
    };
  }>(url);

  return result.data.result;
};

export const downloadReviseReport = async (filter: string): Promise<Blob> => {
  const assemblerUrl = new AssemblerUrl(API.revise.downloadShopReport);
  const url = assemblerUrl.addFilter(filter).getUrl();
  const result = await makeGetFileRequest(url);

  return result.data;
};

export const getReviseStatus = async (): Promise<{ status: 'discussion' | 'done' }> => {
  const result = await makeGetRequest(API.revise.getStatus);

  return result.data;
};

export const getReviseDuration = async (id: string, data: { start: string; end: string; lunch: string }) => {
  const url = API.revise.getDuration
    .replace('$1', id)
    .replace('$2', data.start)
    .replace('$3', data.end)
    .replace('$4', data.lunch);

  const response = await makeGetRequest(url);

  return response.data.result;
};

/**
 * Get revise report
 *
 * @param id
 *
 * @access CAN_READ_SHIFT_EMPLOYEE_CHECKUP
 */
export const getReport = async (id: string) => {
  const url = API.revise.readShiftEmployee.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.shiftEmployee;
};

/**
 * Set revise report
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_SHIFT_EMPLOYEE_CHECKUP
 */
export const setReport = async (id: string, data: any) => {
  const url = API.revise.editShiftEmployee.replace('$1', id);

  return makeFormRequest(url, data);
};
