export interface CommentModalParamsInterface {
  isShow: boolean;
  employeeId: string;
  comment: string;
}

export const modalParams: CommentModalParamsInterface = {
  isShow: false,
  employeeId: '',
  comment: '',
};
