import { AssemblerUrl } from '@/lib/UtilsApi';
import API from '@/config/api-routers';
import { makeGetRequest } from '@/api/requests';

/**
 * Get client logs data
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 */
export const getClientLogs = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.logs.client);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get client logs types
 */
export const getClientLogsType = async () => {
  const url = API.logs.clientTypes;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get employee Logs
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 * @param params
 */
export const getEmployeeLogs = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string,
  params: { employeeId: string; shiftId?: string }
) => {
  const employeeIdFilter = `&filters[0][id]=employeeId&filters[0][value]=${params.employeeId}&`;
  const shiftIdFilter = params.shiftId ? `filters[1][id]=clientMarketShiftId&filters[1][value]=${params.shiftId}` : '';
  const assemblerUrl = new AssemblerUrl(API.logs.employee);
  const url = assemblerUrl
    .addPage(pageCurrent)
    .addAmount(pageAmountItems)
    .addSort(sort)
    .addFilter(`${filter}${employeeIdFilter}${shiftIdFilter}`)
    .getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get employee logs type
 */
export const getEmployeeLogsType = async () => {
  const url = API.logs.employeeTypes;
  const result = await makeGetRequest(url);

  return result.data.result;
};

export const getPartnerLogs = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string,
  params: { partnerId: string; shiftId?: string }
) => {
  const partnerIdFilter = `&filters[0][id]=partnerUuid&filters[0][value]=${params.partnerId}&`;
  const assemblerUrl = new AssemblerUrl(API.logs.partner);
  const url = assemblerUrl
    .addPage(pageCurrent)
    .addAmount(pageAmountItems)
    .addSort(sort)
    .addFilter(`${filter}${partnerIdFilter}`)
    .getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.responseData.result;
};

/**
 * Get partner logs type
 */
export const getPartnerLogsType = async () => {
  const url = API.logs.partnerTypes;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get shop logs
 */
export const getShopLogs = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string,
  shopId: string
) => {
  const shopFilter = `&filters[0][id]=clientMarketId&filters[0][value]=${shopId}`;
  const assemblerUrl = new AssemblerUrl(API.logs.shop);
  const url = assemblerUrl
    .addPage(pageCurrent)
    .addAmount(pageAmountItems)
    .addSort(sort)
    .addFilter(`${filter}${shopFilter}`)
    .getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get shop logs types
 */
export const getShopLogsTypes = async () => {
  const url = `${API.logs.shopTypes}`;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get shop shift logs
 */
export const getShopShiftLogs = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.logs.shift);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};
