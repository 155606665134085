/**
 * Free market shifts store.
 *
 */

import { watch } from 'vue';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { PageSort } from '@/lib/layouts/page/page.interface';
import filterModel from './filter';
import FreeMarketShiftsFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import EmployeesFreeShifts from './employees';
import { SelectItem } from '@/lib/formFactory/select.interface';

import { getFreeMarketShiftsList } from '@/api/freeMarketShifts';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/FreeShifts';
import { getClients } from '@/api/client';

import moment from 'moment';
import { getRegionsAll } from '@/api/region';
import { getAllPartnersList } from '@/api/partners';
import { strings } from '@/lib/stringConst';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { modalParams, FreeMarketShiftModalParamsType } from '@/store/tasks/freeMarketShifts/modalParams';
import { getServiceTypeAll } from '@/api/templates';

export const MODULE_NAME = 'freeMarketShifts';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FreeMarketShifts extends PageBaseModule {
  filter: Filter;
  modalParams: FreeMarketShiftModalParamsType = modalParams;
  dayFilter = {
    key: 'dayFilter',
    title: '',
    name: 'dayFilter',
    class: '',
    previewName: '',
    type: 'select',
    list: [] as SelectItem[],
    current: {
      id: '0',
      value: '',
    },
    action: 'freeMarketShifts/updateSelect',
    initAction: '',
    clearAction: '',
    error: {
      class: '',
      message: '',
    },
  };
  enumDays: Record<string, string> = {
    '-2': 'Позавчера',
    '-1': 'Вчера',
    '0': 'Сегодня',
    '1': 'Завтра',
    '2': 'Послезавтра',
  };
  daysText = ['день', 'дня', 'дней'];
  isLoading = false;

  constructor(module: FreeMarketShifts) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Дыры');
    page.values.actionPagination = 'freeMarketShifts/updatePage';
    this.pageSettings = page.values;
    this.pageSettings.actionsHandler = {
      additional: {
        start: 'freeMarketShifts/getById',
        need: 'freeMarketShifts/getById',
      },
    };

    const filter = new filterModel();
    this.filter = getModule(FreeMarketShiftsFilter);
    this.filter.setFilterName('freeMarketShiftsFilter');
    this.filter.setTemplateClassName('template-lg');
    this.filter.setBtnClassName('col-1-row-6');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetMultiSelect('partners');
        }
      },
      { immediate: true }
    );
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.freeMarketShiftsSort = JSON.stringify(sorts);
  }

  @Mutation
  UPDATE_DATE_START(date: string) {
    (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value = date;
    window.localStorage.freeMarketShiftStart = JSON.stringify(date);
  }

  @Mutation
  UPDATE_DATE_END(date: string) {
    (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value = date;
    window.localStorage.freeMarketShiftEnd = JSON.stringify(date);
  }

  @Mutation
  SET_IS_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async init() {
    EmployeesFreeShifts.SET_WATCHED_IDS(
      window.localStorage.freeMarketwatchedIds ? JSON.parse(window.localStorage.freeMarketwatchedIds) : []
    );
    this.context.commit(
      'SET_SORT',
      window.localStorage.freeMarketShiftsSort ? JSON.parse(window.localStorage.freeMarketShiftsSort) : {}
    );
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.initRange();
    await this.filter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async initRange() {
    const start = window.localStorage.freeMarketShiftStart
      ? JSON.parse(window.localStorage.freeMarketShiftStart)
      : moment().format('DD/MM/YY');
    this.context.commit('UPDATE_DATE_START', start);
    const end = window.localStorage.freeMarketShiftEnd
      ? JSON.parse(window.localStorage.freeMarketShiftEnd)
      : moment().format('DD/MM/YY');
    this.context.commit('UPDATE_DATE_END', end);
  }

  @Action({ rawError: false })
  async initClientsSelect() {
    try {
      const result = await getClients();

      return (Object.values(result.clients) as { id: string; name: string }[]).map(
        (client: { id: string; name: string }) => {
          return {
            id: client.id,
            value: client.name,
            name: client.name,
          };
        }
      );
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initServiceTypes() {
    try {
      const serviceTypes = await getServiceTypeAll();

      return serviceTypes.map(({ id, name }) => ({
        id,
        value: name,
      }));
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initRegionsSelect() {
    try {
      const regionsAll: { id: string; name: string }[] = await getRegionsAll();

      return Object.values(regionsAll).map((region: { id: string; name: string }) => {
        return {
          id: region.id,
          value: region.name,
          name: region.name,
        };
      });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    try {
      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async getList() {
    try {
      this.SET_IS_LOADING(true);

      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filterDates = await this.getFilterDates();
      const filter = `&${filterDates}${this.filter.filterSettings.filter}`;

      const result = await getFreeMarketShiftsList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);

      await this.context.dispatch('setList', result.table);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    } finally {
      this.SET_IS_LOADING(false);
    }
  }

  @Action({ rawError: true })
  getStartMoment() {
    return moment((this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value, 'DD/MM/YY');
  }

  @Action({ rawError: true })
  getEndMoment() {
    return moment((this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value, 'DD/MM/YY');
  }

  @Action({ rawError: true })
  async getFilterDates() {
    const dateStartFormatted = (await this.getStartMoment()).format('YYYY-MM-DD');
    const dateEndFormatted = (await this.getEndMoment()).format('YYYY-MM-DD');

    let datesFilter = `&filters[0][id]=periodStart&filters[0][value]=${dateStartFormatted}`;
    datesFilter += `&filters[1][id]=periodEnd&filters[1][value]=${dateEndFormatted}&`;

    return datesFilter;
  }

  @Action({ rawError: true })
  async updateDateStart(date: string) {
    await this.context.commit('UPDATE_DATE_START', date);

    if ((await this.getStartMoment()) > (await this.getEndMoment())) {
      await this.context.commit('UPDATE_DATE_END', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async updateDateEnd(date: string) {
    await this.context.commit('UPDATE_DATE_END', date);

    if ((await this.getStartMoment()) >= (await this.getEndMoment())) {
      await this.context.commit('UPDATE_DATE_START', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table, this));
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }
}

export default getModule(FreeMarketShifts);
