import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import filterModel from './logsFilter';

import { getEmployeeLogs, getEmployeeLogsType } from '@/api/logs';
import { prepareList } from './logsTable';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';

import moment from 'moment';

const MODULE_NAME = 'employeeLogs';

import EmployeeLogsFilter from './logsFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { strings } from '@/lib/stringConst';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class EmployeeLogsModule extends PageBaseModule {
  employeeId = '';
  tableByShift: TableInterface = {} as TableInterface;
  isTableLoading = true;
  filter: Filter;

  constructor(module: EmployeeLogsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'employeeLogs/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(EmployeeLogsFilter);
    this.filter.setFilterName('employeeLogsFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_EMPLOYEE_ID(id: string) {
    this.employeeId = id;
  }

  @Mutation
  SET_IS_TABLE_LOAD(bool: boolean) {
    this.isTableLoading = bool;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesLogsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_TABLE_BY_SHIFT(table: TableInterface) {
    this.tableByShift = table;
  }

  @Action({ rawError: true })
  async init(employeeId: string) {
    await this.context.commit('SET_EMPLOYEE_ID', employeeId);
    this.context.commit(
      'SET_SORT',
      window.localStorage.employeesLogsSort ? JSON.parse(window.localStorage.employeesLogsSort) : {}
    );

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    this.initListById(employeeId);
  }

  @Action({ rawError: true })
  async initListById(employeeId: string) {
    await this.filter.init();
    this.getListByParams({ employeeId: employeeId });
  }

  @Action({ rawError: true })
  updateList() {
    this.getListByParams({ employeeId: this.employeeId });
  }

  @Action({ rawError: true })
  async getListByParams(params: { employeeId: string; shiftId?: string }) {
    const result = await this.requestByParams(params);
    this.setLogs(result);
  }

  @Action({ rawError: true })
  async requestByParams(params: { employeeId: string; shiftId?: string }) {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = this.filter.filterSettings.filter;

      const result = await getEmployeeLogs(this.pageSettings.pageCurrent, itemsQuery, sort, filter, params);

      return result.table;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setLogs(table: TableInterface) {
    const result = prepareList(table, this.pageSettings.sort);
    this.context.commit('SET_TABLE', result);
  }

  @Action({ rawError: true })
  async initLogsByShift(params: { employeeId: string; shiftId?: string }) {
    try {
      const result = await this.requestByParams(params);
      this.setShiftsLogs(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setShiftsLogs(table: TableInterface) {
    const result = prepareList(table, this.pageSettings.sort);
    this.context.commit('SET_TABLE_BY_SHIFT', result);
    this.updateIsShiftLogsLoading(false);
  }

  @Action({ rawError: true })
  updateIsShiftLogsLoading(bool: boolean) {
    this.context.commit('SET_IS_TABLE_LOAD', bool);
  }

  @Action({ rawError: true })
  async initEventTypes() {
    const types: Record<string, string>[] = await getEmployeeLogsType();
    const typesProcessed = Object.values(types).map((type: Record<string, string>) => {
      return {
        id: type.type,
        value: type.humanType,
      };
    });

    return typesProcessed;
  }

  @Action({ rawError: true })
  initDates() {
    const startDate = moment().startOf('month').format('DD/MM/YY').split('/');

    const today = moment().format('DD/MM/YY').split('/');

    return {
      startDate: { day: startDate[0], month: startDate[1], year: startDate[2] },
      endDate: { day: today[0], month: today[1], year: today[2] },
    };
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getListByParams({ employeeId: this.employeeId });
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getListByParams({ employeeId: this.employeeId });
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    await this.getListByParams({ employeeId: this.employeeId });
  }
}

export default getModule(EmployeeLogsModule);
