import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-flex" }
const _hoisted_2 = { class: "cp-input-fake cp-input-radius-50" }
const _hoisted_3 = { class: "mr-input-fake__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["cp-icon-secondary cp-flex cp-flex--align-center", { disabled: $props.disabled }])
    }, [
      ($props.icon)
        ? (_openBlock(), _createBlock(_component_icon_font, {
            key: 0,
            size: $props.iconSize,
            icon: $props.icon
          }, null, 8, ["size", "icon"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["cp-input-fake__wrapper", { disabled: $props.disabled }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString($props.value), 1)
      ])
    ], 2)
  ]))
}