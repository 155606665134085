import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mr-title__wrapper mr-hide-mobile" }
const _hoisted_2 = { class: "cp-arrow-return__wrapper" }
const _hoisted_3 = { class: "cp-title-page__wrapper" }
const _hoisted_4 = { class: "cp-title-page__wrapper-inner" }
const _hoisted_5 = { class: "cp-title-page" }
const _hoisted_6 = { class: "cp-title__actions" }
const _hoisted_7 = {
  key: 0,
  class: "cd-admin-table-total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_arrow_return = _resolveComponent("arrow-return")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_icon_font, {
                key: 0,
                class: "cp-title-page__icon",
                size: _ctx.iconSize,
                icon: _ctx.icon
              }, null, 8, ["size", "icon"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "icon-badge")
        ]),
        _createElementVNode("h1", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.totalItems)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Всего: " + _toDisplayString(_ctx.totalItems), 1))
          : _createCommentVNode("", true),
        (_ctx.isAdd)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "cp-flex",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toAdd && _ctx.toAdd(...args)))
            }, [
              _withDirectives(_createVNode(_component_icon_font, {
                size: "20",
                icon: "add"
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.addTooltip,
                  void 0,
                  { "bottom-end": true }
                ]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isReturn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "cp-arrow-return",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.returnBefore && _ctx.returnBefore(...args)))
            }, [
              _createVNode(_component_arrow_return, { color: "#9B9BC7" })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}