import {
  email,
  url,
  maxLength,
  minLength,
  required,
  numeric,
  integer,
  sameAs,
  helpers,
  minValue,
  maxValue,
  or,
} from '@vuelidate/validators';
import { ValidationRuleWithoutParams, ValidationRuleWithParams, ValidatorFn, ValidationRule } from '@vuelidate/core';
import moment from 'moment';

export interface ValidatorListInterface {
  required: () => ValidationRuleWithoutParams;
  email: () => ValidationRuleWithoutParams;
  url: () => ValidationRuleWithoutParams;
  minLength: (min: number) => ValidationRuleWithParams<{ min: number }>;
  maxLength: (max: number) => ValidationRuleWithParams<{ max: number }>;
  minValue: (min: number) => ValidationRuleWithParams<{ min: number }>;
  maxValue: (max: number) => ValidationRuleWithParams<{ max: number }>;
  numeric: () => ValidationRuleWithoutParams;
  integer: () => ValidationRuleWithoutParams;
  sameAs: (equalTo: unknown) => ValidationRuleWithParams<{ equalTo: unknown; otherName: string }>;
  regexp: (regexp: RegExp | string) => ValidatorFn;
  unRegexp: (regexp: RegExp | string) => ValidatorFn;
  dateValid: (params: {
    format: string;
    minValue?: number;
    maxValue?: number;
    yearRangeMin?: number;
    yearRangeMax?: number;
  }) => ValidatorFn;
  or: (params: ValidationRule<unknown>[]) => ValidationRuleWithoutParams<unknown>;
}

const validators: ValidatorListInterface = {
  required: () => required,
  email: () => email,
  url: () => url,
  minLength: (min: number) => minLength(min),
  maxLength: (max: number) => maxLength(max),
  minValue: (min: number) => minValue(min),
  maxValue: (max: number) => maxValue(max),
  numeric: () => numeric,
  integer: () => integer,
  sameAs: (eq: unknown) => sameAs(eq),
  regexp: (regexp: RegExp | string) => (value: string) => !helpers.req(value) || RegExp(regexp).test(value),
  unRegexp: (regexp: RegExp | string) => (value: string) => !helpers.req(value) || !RegExp(regexp).test(value),
  or: (validators: ValidationRule<unknown>[]) => or(...validators),
  dateValid: (params: {
    format: string;
    minValue?: number;
    maxValue?: number;
    yearRangeMin?: number;
    yearRangeMax?: number;
  }) => (value: string) => {
    if (!value) return true;
    const date = moment(value, params.format);
    if (!date.isValid()) return false;
    if (params.minValue && date.unix() < (params.minValue as number)) return false;
    if (params.maxValue && date.unix() > (params.maxValue as number)) return false;

    const flagMin =
      params.yearRangeMin && moment(value, params.format).add(params.yearRangeMin, 'years').unix() > moment().unix();

    const flagMax =
      params.yearRangeMax && moment(value, params.format).add(params.yearRangeMax, 'years').unix() < moment().unix();

    return !(flagMin || flagMax);
  },
};

export default validators;
