import { ref, provide } from 'vue';

import ResponseHandlerModule from '@/store/modules/responseHandler';

export default function formValidate(form: any) {
  const valid = ref(true);
  const inputsValidate = ref(0);

  provide('validateInputs', inputsValidate);

  const getInputs = () => {
    const formElements = form.value.elements;
    const inputs = [];

    for (let i = 0; i < formElements.length; i++) {
      if (
        formElements[i].nodeName === 'INPUT' ||
        formElements[i].nodeName === 'TEXTAREA' ||
        formElements[i].nodeName === 'SELECT'
      ) {
        inputs.push(formElements[i]);
      }
    }

    return inputs;
  };

  const getInputsErrors = (messages?: boolean) => {
    const inputs = getInputs();

    const inputsErrors = inputs.filter((input: any) => {
      return input.dataset.errorMessages;
    });

    if (messages) {
      return inputsErrors.map((input: any) => {
        return {
          name: input.dataset.name ?? '',
          message: input.dataset.errorMessages,
        };
      });
    }

    return inputsErrors;
  };

  const generateMessage = () => {
    const inputErrors = getInputsErrors(true);

    const inputsMessages = inputErrors.map((message: any) => {
      return message.message;
    });

    const errorsEmpty = inputsMessages.filter((error: any) => {
      return error === 'Поле не заполнено';
    });

    if (errorsEmpty.length > 1) {
      return 'Заполните обязательные поля';
    }

    if (inputsMessages.length) {
      const name = inputErrors[0].name ? `${inputErrors[0].name}:` : '';

      return `${name} ${inputErrors[0].message.toLowerCase()}`;
    }
  };

  const triggerValidateInputs = () => {
    inputsValidate.value++;
  };

  const validate = async (showMessage?: boolean) => {
    await triggerValidateInputs();

    const inputsErrors = await getInputsErrors();

    if (inputsErrors.length) {
      const message: any = await generateMessage();

      if (showMessage) {
        ResponseHandlerModule.showNotify({ message, type: 'fail' });
      }

      valid.value = false;

      return false;
    }

    valid.value = true;

    return true;
  };

  return {
    inputsValidate,
    form,
    getInputs,
    getInputsErrors,
    generateMessage,
    validate,
    triggerValidateInputs,
    valid,
  };
}
