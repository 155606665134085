import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "cp-admin-title__wrapper app-header-main" }
const _hoisted_2 = { class: "cd-admin-table-total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_page = _resolveComponent("title-page")!
  const _component_filter_preview = _resolveComponent("filter-preview")!
  const _component_filter_modal = _resolveComponent("filter-modal")!
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_arrow_return = _resolveComponent("arrow-return")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title && _ctx.title.length > 0)
      ? (_openBlock(), _createBlock(_component_title_page, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "mr-filter-dropdown__dark",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFilter && _ctx.closeFilter(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "app-header-main app-header-main-center",
      style: _normalizeStyle({ flexGrow: _ctx.isCenter ? '1' : 'auto' })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["cp-admin-control__wrapper cp-admin-control__filters", { active: _ctx.isShow }])
      }, [
        _createVNode(_component_filter_preview, {
          "filter-preview": _ctx.filterSettings.filterPreview,
          "filter-model": _ctx.filterSettings.filterModel,
          action: _ctx.filterSettings.filterHandlers.removeSelected,
          onUpdateList: _ctx.updateList,
          onClickItem: _ctx.toggleFilter,
          onRemovePreviewTag: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('removePreviewTag', $event)))
        }, null, 8, ["filter-preview", "filter-model", "action", "onUpdateList", "onClickItem"]),
        _createElementVNode("div", {
          class: "cp-admin-control__filters-overlay",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args)))
        }),
        _createVNode(_component_filter_modal, {
          ref: "filterModal",
          settings: _ctx.filterSettings,
          filters: _ctx.filterSettings.filterModel,
          "focus-key": _ctx.focusKey,
          handlers: _ctx.filterSettings.filterHandlers,
          onResetFilters: _ctx.resetFilters,
          onUpdateList: _ctx.updateList
        }, {
          filters: _withCtx(() => [
            _renderSlot(_ctx.$slots, "filters")
          ]),
          "filter-buttons": _withCtx(() => [
            _renderSlot(_ctx.$slots, "filter-buttons")
          ]),
          _: 3
        }, 8, ["settings", "filters", "focus-key", "handlers", "onResetFilters", "onUpdateList"])
      ], 2),
      _createElementVNode("div", _hoisted_2, [
        (typeof _ctx.totalItems === 'number')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" Всего: " + _toDisplayString(_ctx.totalItems), 1)
            ], 64))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "additional")
      ])
    ], 4),
    (!_ctx.isSettingsState)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
          (_ctx.isToAddHidden !== true)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "cp-admin-control__wrapper",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toAdd && _ctx.toAdd(...args)))
              }, [
                _withDirectives(_createVNode(_component_add_icon, {
                  class: "cp-cursor-pointer",
                  color: "#9B9BC7"
                }, null, 512), [
                  [_directive_tooltip, _ctx.addTooltip]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isSettingsIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "cp-admin-control__wrapper cp-icon-primary-2 cp-cursor-pointer cp-flex",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toSettings && _ctx.toSettings(...args)))
              }, [
                _createVNode(_component_icon_font, {
                  size: "18",
                  icon: "settings"
                })
              ]))
            : _createCommentVNode("", true)
        ])
      : _createCommentVNode("", true),
    (_ctx.isSettingsState)
      ? _renderSlot(_ctx.$slots, "default", { key: 3 }, () => [
          _createElementVNode("div", {
            class: "cp-arrow-return",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.returnFromSettings && _ctx.returnFromSettings(...args)))
          }, [
            _createVNode(_component_arrow_return, { color: "#9B9BC7" })
          ])
        ])
      : _createCommentVNode("", true)
  ]))
}