interface messageParams<P> {
  $params: P;
}

const messages = {
  required: 'Обязательное поле',
  minLength: ({ $params }: messageParams<{ min: string }>) => `Это поле должно быть длиной не менее ${$params.min}`,
  maxLength: ({ $params }: messageParams<{ max: string }>) => `Максимально допустимая длина ${$params.max}`,
  minValue: ({ $params }: messageParams<{ min: string }>) => `Минимально допустимое значение ${$params.min}`,
  maxValue: ({ $params }: messageParams<{ max: string }>) => `Максимально допустимое значение ${$params.max}`,
  email: 'Некорректный формат адреса',
  numeric: '',
  integer: 'Доступны только цифры',
  decimal: '',
  url: 'Неверный формат ссылки',
  or: '',
  and: '',
  not: '',
  // custom validators
  regexp: 'Неверный формат',
} as { [index: string]: string | ((params: messageParams<any>) => string) };

export default messages;
