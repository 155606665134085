import { DEFAULT_HOURS } from '@/store/customers/templatesEntity';

export function getHintOfRestrictionByHours(min: number, max: number): { hint: string; icon: string } {
  let hint = 'Внимание! Заказ будет доступен исполнителям с любым опытом';
  let icon = 'warning';

  if (min > max) {
    hint = 'Внимание! Заказ будет недоступен исполнителям';
  } else if (min > 0) {
    hint = 'Внимание! Заказ будет доступен ограниченному числу исполнителей';
  } else if (max >= DEFAULT_HOURS) {
    hint = 'Внимание! Заказ будет доступен исполнителям с любым опытом';
    icon = 'check-mark';
  } else if (max === min) {
    hint = 'Внимание! Заказ будет доступен только новичкам';
  } else {
    hint = 'Внимание! Заказ будет доступен не всем опытным исполнителям';
  }

  return { hint, icon };
}
